export const FILTER_PLACEHOLDER_MATERIALS = 'FILTER_PLACEHOLDER_MATERIALS';
export const GET_PLACEHOLDER_MATERIALS = 'GET_PLACEHOLDER_MATERIALS';
export const GET_PLACEHOLDER_MATERIALS_BULK = 'GET_PLACEHOLDER_MATERIALS_BULK';
export const SORT_PLACEHOLDER_MATERIALS = 'SORT_PLACEHOLDER_MATERIALS';


export function filterPlaceholderMaterials(filterParameters) {
    console.log("Action FILTER_PLACEHOLDER_MATERIALS", filterParameters);
    return { type: FILTER_PLACEHOLDER_MATERIALS, data: filterParameters };
};

export function sortPlaceholderMaterials(sortParameter, direction) {
    console.log("Action SORT_PLACEHOLDER_MATERIALS");
    return { type: SORT_PLACEHOLDER_MATERIALS, data: { sortParameter, direction } };
};

export function getPlaceholderMaterials(queryParameters) {
    console.log("Action GET_PLACEHOLDER_MATERIALS", queryParameters);
    return { type: GET_PLACEHOLDER_MATERIALS, data: queryParameters };
};

export function getPlaceholderMaterialsBulk(queryParameters) {
    console.log("Action GET_PLACEHOLDER_MATERIALS_BULK", queryParameters);
    return { type: GET_PLACEHOLDER_MATERIALS_BULK, data: queryParameters };
};
