import * as React from 'react';
import Order from './tables/order';
import Product from './tables/product';
import SerialNumber from './tables/serialNumber';
import Warranty from './tables/warranty';

export default function SerialNumberDetails() {

    return (<>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <SerialNumber />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Order />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Product />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Warranty />
        </div>
    </>)
}
