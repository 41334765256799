import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import DataObjectIcon from '@mui/icons-material/DataObject';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SerialNumberReference() {

    const location = useLocation();
    let serialNumber = location.pathname.split("/")[location.pathname.split("/").length - 1];

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const material = useSelector((state) => {
        let _material = state?.materials?.find(m => m.serial_number === serialNumber);
        if (_material) {
            return JSON.parse(JSON.stringify(_material));
        } else return {}
    });
    const serialNumberReference = material?.serialNumberReference;

    const rawSAPRows = [

        { name: 'Customer Group 3', value: serialNumberReference?.customer_group3 },
        { name: 'Customer Order ID', value: serialNumberReference?.customer_order_id },
        { name: 'Delegated To', value: serialNumberReference?.delegated_to },
        { name: 'Extract Date', value: serialNumberReference?.extract_date },
        { name: 'Order Parent Line', value: serialNumberReference?.order_parent_line },
        { name: 'Product Type', value: serialNumberReference?.product_type },
        { name: 'Shipping Date', value: serialNumberReference?.shipping_date },
        { name: 'Warranty End Date', value: serialNumberReference?.warranty_end_date },
        { name: 'Warranty Service Level', value: serialNumberReference?.warranty_service_level },
        { name: 'Warranty Start Date', value: serialNumberReference?.warranty_start_date },
        { name: 'Orderable Item', value: serialNumberReference?.orderable_item },
        { name: 'Technical Item', value: serialNumberReference?.technical_item },
    ];

    const enrichedSAPRows = [
        { name: 'Bundle Master', value: serialNumberReference?.bundle_master?.toString() },
        { name: 'Bundle Reference', value: serialNumberReference?.bundle_reference },
    ];

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <DataObjectIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Serial Number Reference
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="serial number reference table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                textAlign: 'center'
                                            }}
                                            colSpan="100%"
                                        >
                                            Enriched SAP Data
                                        </TableCell>
                                    </TableRow>
                                    {enrichedSAPRows.map((row, i) => {
                                        if (row?.value) {
                                            return (
                                                <TableRow>
                                                    <TableCell key={row.name + i + " namecell"}>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell key={row.value + i + " valuecell"}>
                                                        {row.value}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                textAlign: 'center'
                                            }}
                                            colSpan="100%"
                                        >
                                            Raw SAP Data
                                        </TableCell>
                                    </TableRow>
                                    {rawSAPRows.map((row, i) => {
                                        if (row?.value) {
                                            return (
                                                <TableRow>
                                                    <TableCell key={row.name + i + " namecell"}>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell key={row.value + i + " valuecell"}>
                                                        {row.value}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}