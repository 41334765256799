import {
    FILTER_SYSTEM_APPLICATIONS,
    SORT_SYSTEM_APPLICATIONS,
    GET_SYSTEM_APPLICATIONS,
    GET_SYSTEM_APPLICATION_DETAILS,
} from '../actions';

import { Auth } from 'aws-amplify';

import { API, graphqlOperation } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import * as queries from '../../../graphql/queries.js';
import {restfulResources, getFromServer} from '../../global/middleware';

let originalFetch = require('isomorphic-fetch');
let fetch = require('fetch-retry')(originalFetch);

API.configure(config)

let questObject;
let getResult;

export function applicationMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            //TODO: Error handling
            switch (action.type){
                case FILTER_SYSTEM_APPLICATIONS:
                    break;
                case SORT_SYSTEM_APPLICATIONS:
                    break;
                case GET_SYSTEM_APPLICATIONS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'systemApplications';

                    getResult = await getFromServer(questObject);
                    if (getResult.responseCode < 202 && getResult !== 0) {
                        action.results = getResult.results;
                    } else {
                        action.errors = { responseCode: getResult.responseCode };
                        console.log('action.errors', action.errors, 'getResult', getResult);
                    };
                    break;
                case GET_SYSTEM_APPLICATION_DETAILS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'systemApplications';

                    getResult = await getFromServer(questObject);
                    if (getResult.responseCode < 202 && getResult !== 0) {
                        action.results = getResult.results;
                    } else {
                        action.errors = { responseCode: getResult.responseCode };
                        console.log('action.errors', action.errors, 'getResult', getResult);
                    };

                };
            return next(action);

        }
    }
}