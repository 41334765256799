import * as React from 'react';
import { Box, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Chip, ListItem } from '@mui/material';
import { useDispatch } from 'react-redux';

export default function MultiValueInputWithWarning(props) {
    const [inputValue, setInputValue] = React.useState("");
    const dispatch = useDispatch();
    const family = props?.family;
    const type = props?.type;

    const handleUserInput = (e) => {
        setInputValue(e.target.value);
        props.setCurrentParam(e.target.value);
    };

    const resetInputField = () => {
        setInputValue("");
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e?.target?.value && e?.target?.value != '') {
                e.stopPropagation();
                handleUserInput(e);
                addParam();
            };
        };
    };

    const addParam = () => {
        let splittedParams = [];
        let rawParams = props.currentParam.match(/(".*?"|[^\s;]+)/g) || [];
        for (let i = 0; i < rawParams.length; i++) {
            if (rawParams[i][0] === '"') {
                splittedParams.push(rawParams[i].slice(1, -1));
            } else {
                splittedParams = splittedParams.concat(rawParams[i].split(";"));
            }
        }

        let trimmedParams = splittedParams.map(param => param.trim());
        if (trimmedParams.length > 0) {
            dispatch(props.setQueryParams({ [family]: { [type]: trimmedParams } }));
        }
        props.setCurrentParam();
        resetInputField();
    };

    return (
        <React.Fragment>
            <Box
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'stretch',
                    alignItems: 'center',
                    flexDirection: 'row'
                }}
            >
                <Tooltip title={props?.warningText} placement="bottom">
                    <TextField id="outlined-basic" label={props.label} variant="outlined" onChange={(e) => handleUserInput(e)}
                        style={{
                            flex: 8.5,
                            height: '5%'
                        }} value={inputValue}
                        onKeyDown={handleKeyPress}
                    />
                </Tooltip>
            </Box>
            <div
                style={{
                    flexDirection: 'row',
                    width: '100%',
                    overflow: 'auto',
                    maxWidth: '100%',
                    display: 'inline'
                }}
            >
                {props.queryParams?.map((chip, i) => {
                    return (
                        <Chip
                            style={{
                                marginRight: '1%',
                                marginBottom: '0.1%'
                            }}
                            label={chip}
                            onDelete={props.handleDelete(chip)}
                            key={chip + i}
                        />
                    )
                })}
            </div>
        </React.Fragment>
    );
}