import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from 'react-redux';
import { LOADING, setUserSession } from "../redux/global/actions";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {getLogo} from '../widgets/utils'


export default function Login(props) {
    const userSession = useSelector((state) => state.userSession)
    const dispatch = useDispatch();

    useEffect(() => {
        async function getSession() {
            try {
                dispatch({ type: LOADING, loading: true });
                var session = await Auth.currentSession();
                dispatch(setUserSession({ session }));
            } catch (err) {
                console.log(err);
                handleLogin();
            };
        };
        getSession();
    }, []);
    async function handleLogin() {
        try {
            Auth.federatedSignIn({ customProvider: 'Alcatel-Login' });
        } catch (e) {
            alert(e);
        };
    };
    return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30%',
              height: '30%',
              border: `5px solid #6b469e`,
              position: 'relative',
              borderRadius: '5%'
            }}
          >
            <div
                style={{
                  position: 'absolute',
                  top: '0',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  height: '25%',
                  backgroundColor: '#6b469e',
                  alignItems: 'center',
                  width: '100%',
                  borderRadius: '5%'

                }}
              >
                <div
                  style={{
                    width: '3vw',
                    height: '3vw',
                    backgroundImage: getLogo(),
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                  }}
                />
                <Typography>
                  Victoria
                  <Typography fontSize='0.8em'>
                    Explore the datalake
                  </Typography>
                </Typography>
              </div>
            <Button variant="contained" color="primary" onClick={handleLogin}>
              Login
            </Button>
          </div>
        </div>
      );
};
