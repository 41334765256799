import {
    GET_SYSTEMS,
    FILTER_SYSTEMS,
    SORT_SYSTEMS,
    GET_SYSTEM_DETAILS,
    EXPORT_SYSTEMS_TO_CSV,
} from '../actions';
import {LOADING} from '../../global/actions'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Papa from 'papaparse';

// TODO GLOBAL
const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {}
    },
};
// TODO GLOBAL
function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

// TODO GLOBAL
function downloadBulkResult(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

// TODO GLOBAL
const getNestedValueFromString = (from, ...selectors) =>
    [...selectors].map(s =>
        s
            .replace(/\[([^\[\]]*)\]/g, '.$1.')
            .split('.')
            .filter(t => t !== '')
            .reduce((prev, cur) => prev && prev[cur], from)
    );

function systemReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    switch (action.type){
        case LOADING:
            break;
        default:
            state.loading = false;
            break;
    };
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;
    switch (action.type) {
        case GET_SYSTEMS:
            state = Object.assign({}, state, { systems: action.results, filteredSystems: action.results });
            return state;
        case FILTER_SYSTEMS:
            if (!state.systems) {
                state.systems = [];
            };
            let filterSystemParams = action.data;
            let filteredSystems = [];

            let newSystems = state.systems.filter(m => {
                if (filterSystemParams?.systemIds?.length > 0) {
                    for (let systemIds of filterSystemParams?.systemIds) {
                        if (!m?.system_id.startsWith(systemIds)) {
                            return false;
                        };
                    };
                };
                if (filterSystemParams?.names?.length > 0) {
                    for (let names of filterSystemParams?.names) {
                        if (!m?.name.startsWith(names)) {
                            return false;
                        };
                    };
                };
                if (filterSystemParams?.businessPartners?.length > 0) {
                    for (let businessPartner of filterSystemParams?.businessPartners) {
                        if (!m?.business_partner?.company_id.startsWith(businessPartner)) {
                            return false;
                        };
                    };
                };
                if (filterSystemParams?.soldTos?.length > 0) {
                    for (let soldTos of filterSystemParams?.soldTos) {
                        if (!m?.sold_to?.applicant_id.startsWith(soldTos)) {
                            return false;
                        };
                    };
                };
                if (filterSystemParams?.partNumbers?.length > 0) {
                    for (let partNumber of filterSystemParams?.partNumbers) {
                        if (!m?.part_number.startsWith(partNumber)) {
                            return false;
                        };
                    };
                };
                if (filterSystemParams?.orderIds?.length > 0) {
                    for (let orderId of filterSystemParams?.orderIds) {
                        if (!m?.order?.order_id.startsWith(orderId)) {
                            return false;
                        };
                    };
                };
                if (filterSystemParams?.filterCreationDateStart) {
                    if (!moment(filterSystemParams.filterCreationDateStart).isBefore(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterSystemParams?.filterCreationDateEnd) {
                    if (!moment(filterSystemParams.filterCreationDateEnd).isAfter(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterSystemParams?.filterModificationDateStart) {
                    if (!moment(filterSystemParams.filterModificationDateStart).isBefore(moment(m.modification_date))) {
                        return false
                    }
                };
                if (filterSystemParams?.filterModificationDateEnd) {
                    if (!moment(filterSystemParams.filterModificationDateEnd).isAfter(moment(m.modification_date))) {
                        return false
                    }
                };
                return true
            });
            filteredSystems = filteredSystems.concat(newSystems)

            state = Object.assign({}, state, { filteredSystems });
            return state;
        case SORT_SYSTEMS:
            console.log("SORT_SYSTEMS", action.data);
            const systemParam = action.data.sortParameter;
            const systemDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredSystems?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, systemParam)?.toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, systemParam)?.toString()?.toUpperCase();
                if (systemDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state;
        case GET_SYSTEM_DETAILS:
            if (!state.systems) {
                state.systems = [];
            };
            let system;

            if (action?.results && action.results?.length) {
                system = action.results[0];
                if (system?.entitlements) {
                    const contractsSet = state.contracts instanceof Set ? state.contracts : new Set(state.contracts);
                    for (let contract of system.entitlements) {
                        let contractIdentifier = contract.contract_reference + contract.type
                        contract.contract_identifier = contractIdentifier;
                        contractsSet.add(contract);
                    }
                    state.contracts = Array.from(contractsSet);
                }
                state.systems = state.systems.filter(s => s.system_id !== system.system_id);
                state.systems.push(system);
            };
            state = Object.assign({}, state, { loading: false });
            return state;
        case EXPORT_SYSTEMS_TO_CSV:
            console.log("EXPORT_SYSTEMS_TO_CSV", action.CSVSystems);
            state = Object.assign({}, state, { CSVSystems: action.CSVSystems });
            download(uuidv4() + ".csv", action.CSVSystems);
            return state;
        default:
            return state;
    }
    return state;
}

export default systemReducer