import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Client() {

    const location = useLocation();
    let licenseId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const license = useSelector((state) => {
        let _license = state?.licenses?.find(m => m.license_id === licenseId);
        if (_license) {
            return JSON.parse(JSON.stringify(_license));
        } else return {}
    });

    const rows = [
        { name: 'Support Partner', value: license?.support_partner?.company_id, hover: license?.support_partner?.source },
        { name: 'End User', value: license?.end_user?.company_id, hover: license?.end_user?.source },
        { name: 'Sold To', value: license?.sold_to?.applicant_id },
        { name: 'Business Partner', value: license?.business_partner?.company_id, hover: license?.business_partner?.source },
    ];

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <PersonIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Client (Source: Datalake)
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="client table">
                                <TableBody>
                                    {rows.map((row, i) => {
                                        if (row?.value) {
                                            return (
                                                <>
                                                    {row?.value ? <Tooltip
                                                        title={row?.hover ? row?.hover : ""}
                                                        placement='right'
                                                    >
                                                        <TableRow>
                                                            <TableCell key={row.name + i + " namecell"}>
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell key={row.value + i + " valuecell"}>
                                                                {row.value}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Tooltip> : null}
                                                </>

                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}