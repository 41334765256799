import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import SystemApplication from './tables/systemApplication';
import Contracts from './tables/contracts';
import Customer from './tables/customer';
import { getSystemApplicationDetails } from '../redux/application/actions';
import { getContractDetails } from '../redux/contract/actions';

export default function SystemApplicationDetails() {

    const dispatch = useDispatch();
    const location = useLocation();

    let applicationId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const systemApplication = useSelector((state) => {
        let _systemApplication = state?.systemApplications?.find(s => s.application_id === applicationId);
        if (_systemApplication) {
            return JSON.parse(JSON.stringify(_systemApplication));
        } else return {}
    });
    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!systemApplication && !loading && !errors) {
            dispatch(getSystemApplicationDetails(applicationId));
        };
    }, []);

    return (<>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <SystemApplication />
        <div
                style={{ marginLeft: '1%' }}
            />
            <Customer />
        </div>
        <div
            style={{ marginBottom: '1%' }}
        />
        <Contracts getContractDetails={getContractDetails} />
    </>)
}
