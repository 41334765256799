import * as React from 'react';
import { Typography, List, ListItem } from '@mui/material';

export default function Release() {

    return (
        <React.Fragment>
            <Typography variant='h4' style={{paddingBottom:'2%'}}>
                Release Notes:
            </Typography>
            <Typography variant='h6'>
                24th of August 2023
            </Typography>
            <Typography variant='body1'>
                <List>
                    <ListItem>
                        * Added permissions management
                    </ListItem>
                    <ListItem>
                        * Added licenses search and detail pages
                    </ListItem>
                    <ListItem>
                        * Added logout
                    </ListItem>
                    <ListItem>
                        * Various fixes (order display, ...)
                    </ListItem>
                    <ListItem>
                        * Large internal Redux refactor
                    </ListItem>
                </List>
            </Typography>
            <Typography variant='h6'>
                26th of April 2023
            </Typography>
            <Typography variant='body1'>
                <List>
                    <ListItem>
                        * Deployed to Victoria domain
                    </ListItem>
                    <ListItem>
                        * Added placeholder materials search page
                    </ListItem>
                    <ListItem>
                        * Added serial numbers search page
                    </ListItem>
                    <ListItem>
                        * Added filtering per column
                    </ListItem>
                    <ListItem>
                        * Added various popups and help dialogs
                    </ListItem>
                    <ListItem>
                        * Added bound material counts for contracts
                    </ListItem>
                </List>
            </Typography>
        </React.Fragment>
    );
};

