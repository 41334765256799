import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { Accordion, AccordionDetails, Typography, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PlaceholderMaterials(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    let contractReference = location.pathname.split("/")[location.pathname.split("/").length - 2];
    let contractType = location.pathname.split("/")[location.pathname.split("/").length - 1].replace(/%20/g, " ");

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    let contract = useSelector((state) => {
        let _contract = state?.contracts?.find(c => c.contract_reference === contractReference && c.type === contractType);
        if (_contract) {
            return JSON.parse(JSON.stringify(_contract));
        } else return {}
    });

    const placeholderMaterials = contract?.entitlement?.filter(m=>m.__typename === 'Material_Placeholder');

    const nameRows = [
        { name: 'Serial Number' },
        { name: 'Creation Date' },
        { name: 'Modification Date' },
        { name: 'Order ID' },
        { name: 'Order Line' },
        { name: 'Part Number' },
        { name: 'Bundle Reference' },
    ];

    function handleSelectMaterial(serialNumber, orderId, orderLine) {
        dispatch(props.getMaterialDetails(serialNumber, orderId, orderLine));
        navigate('/materials/details/' + serialNumber);
    };
    const pendingMaterials = placeholderMaterials?.filter(material => material.status?.toLowerCase() === 'pending') || [];
    const activeMaterials = placeholderMaterials?.filter(material => material.status?.toLowerCase() === 'active') || [];
    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <WebAssetIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Placeholder Materials
                            </Typography>
                        </AccordionSummary>
                        <Typography>
                            Total placeholder materials: {placeholderMaterials?.length ?? 0}, of which pending: {pendingMaterials.length}, and active: {activeMaterials.length}
                        </Typography>
                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="contracts table">
                                <TableRow>
                                    {nameRows?.map((row) => {
                                        return (
                                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: "bolder" }}>
                                                {row.name}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                                {placeholderMaterials?.map((material) => {
                                    const valueRows = [
                                        { value: material?.serial_number },
                                        { value: material?.creation_date },
                                        { value: material?.modification_date },
                                        { value: material?.order?.order_id },
                                        { value: material?.order?.order_line },
                                        { value: material?.part_number },
                                        { value: material?.bundle_reference },
                                    ];
                                    return (
                                        <>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                hover
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => { handleSelectMaterial(material?.serial_number, material?.order?.order_id, material?.order?.order_line) }}

                                            >

                                                {
                                                    valueRows?.map((row, i) => {
                                                        return (

                                                            <TableCell align="center" style={{ position: 'relative' }}>
                                                                {row.value}
                                                            </TableCell>

                                                        )
                                                    })
                                                }

                                            </TableRow>
                                        </>
                                    )
                                })}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}