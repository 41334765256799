import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMaterialDetails } from '../../redux/material/actions';


export default function MaterialParents(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const parent = props?.material?.parent;

    function handleSelectMaterial(serialNumber) {
        dispatch(getMaterialDetails(serialNumber));
        navigate('/materials/details/' + serialNumber);
    };

    return (
        <React.Fragment>
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '30%' }} size="small" aria-label="contracts table">
                        <TableRow>
                            <TableCell
                                style={{
                                    backgroundColor: "#6b469e",
                                    color: "white",
                                }}
                                colSpan={100}
                            >
                                Parent
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                Serial number
                            </TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                handleSelectMaterial(parent?.serial_number);
                            }}
                        >
                            <TableCell align="center">{parent?.serial_number}</TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );

}