import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Order() {

    const location = useLocation();
    let licenseId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const license = useSelector((state) => {
        let _license = state?.licenses?.find(m => m.license_id === licenseId);
        if (_license) {
            return JSON.parse(JSON.stringify(_license));
        } else return {}
    });

    const order = license?.order;
    console.log('order', order)

    const rows = [
        { name: 'Order ID', value: order?.order_id },
        { name: 'Order Line', value: order?.order_line },
        { name: 'Order Line Type', value: order?.order_line_type },
        { name: 'Order Line Type ID', value: order?.order_line_type_id },
        { name: 'Parent Order Line', value: order?.parent_order_line },
        { name: 'Part Description', value: order?.part_description },
        { name: 'Part Number', value: order?.part_number },
        { name: 'Quantity', value: order?.quantity },
        { name: 'Sold To', value: order?.sold_to },
        { name: 'Purchase Order', value: order?.purchase_order }
    ];

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <ReceiptIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Order (Source: Raw SAP)
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="serial number reference table">
                                <TableBody>
                                    {rows.map((row, i) => {
                                        if (row?.value) {
                                            return (
                                                <TableRow>
                                                    <TableCell key={row.name + i + " namecell"}>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell key={row.value + i + " valuecell"}>
                                                        {row.value}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}