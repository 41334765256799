import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import {getSystemApplicationDetails} from '../redux/application/actions';
import {getSystemDetails} from '../redux/system/actions';
import {getMaterialDetails} from '../redux/material/actions';
import {exportContractMaterialsToCSV, getContractDetails} from '../redux/contract/actions';
import {getLicenseDetails} from "../redux/license/actions";
import AlertModal from '../widgets/alertModal';
import ContractServices from './tables/contractServices';
import Main from './tables/main';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

/*
import ContractServices from './tables/contractServices';
import HardwareSupportEntitlement from './tables/hardwareSupportEntitlement';
*/
import Materials from './tables/materials';
import PlaceholderMaterials from './tables/placeholderMaterials';
import {Button, Fab, Tooltip, Typography} from '@mui/material';
import Applications from './tables/applications';
import Systems from './tables/systems';
import Licenses from "./tables/licenses";


export default function ContractDetails() {

    const location = useLocation();
    const dispatch = useDispatch();
    let contractReference = location.pathname.split("/")[location.pathname.split("/").length - 2];
    let contractType = location.pathname.split("/")[location.pathname.split("/").length - 1].replace(/%20/g, " ");

    let contractIdentifier = contractReference + contractType;

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    let contract = useSelector((state) => {
        let _contract = state?.contracts?.find(c => c.contract_identifier === contractIdentifier);
        if (_contract) {
            return JSON.parse(JSON.stringify(_contract));
        } else return {}
    });

    React.useEffect(() => {
        if (!contract && !loading && !errors) {
            dispatch(getContractDetails(contractReference, contractType));
        };
    }, []);
    const [material, setMaterial] = React.useState();
    const [application, setApplication] = React.useState();
    const [system, setSystem] = React.useState();
    const [license, setLicense] = React.useState();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(document.getElementById("menuAnchor"));
        dispatch(exportContractMaterialsToCSV(contract));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const filterDescription = 'Export all materials for this contract';

    const placeholderMaterials = contract?.entitlements?.filter(m => m.__typename === 'Material_Placeholder');
    const systems = contract?.entitlements?.filter(m => m.__typename === 'System');
    const applications = contract?.entitlements?.filter(m => m.__typename === 'Application');
    const materials = contract?.entitlements?.filter(m => m?.__typename === 'Material');
    const licenses = contract?.entitlements?.filter(m => m?.__typename === 'License');
    return (<>
        <AlertModal/>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '1%'
            }}
        >
            <Main/>

            <div
                style={{marginLeft: '1%'}}
            />
            <div>
                <ContractServices services={contract?.services}/>
            </div>
            <div style={{marginBottom: '1%'}}/>
        </div>
        <div>
            {!materials || materials?.length === 0 ? null :
                <Materials onChange={setMaterial} getMaterialDetails={getMaterialDetails}/>}
            {!placeholderMaterials || placeholderMaterials?.length === 0 ? null :
                <PlaceholderMaterials onChange={setMaterial} getMaterialDetails={getMaterialDetails}/>}
            {!applications || applications?.length === 0 ? null :
                <Applications onChange={setApplication} getApplicationDetails={getSystemApplicationDetails}/>}
            {!systems || systems?.length === 0 ? null :
                <Systems onChange={setSystem} getSystemDetails={getSystemDetails}/>}
            {!licenses || licenses?.length === 0 ? null :
                <Licenses onChange={setLicense} getLicenseDetails={getLicenseDetails}/>}
            {(!placeholderMaterials || placeholderMaterials?.length === 0) && (!applications || applications?.length === 0) && (!systems || systems?.length === 0) && (!materials || materials?.length === 0) && (!licenses || licenses?.length === 0) ? (
                <div>No entitlements found.</div>
            ) : null}
        </div>
        <Tooltip
            title={<Typography fontSize={17}>{filterDescription}</Typography>}
        >
            <Fab
                style={{
                    position: 'absolute',
                    right: 10,
                    bottom: 10,
                    backgroundColor: "#6b469e",
                    color: "white",
                    height: "5em",
                    width: "5em"
                }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <div
                    id="menuAnchor"
                    style={{
                        position: 'relative',
                        bottom: 500,
                        right: 250
                    }}
                >
                </div>
                <FileDownloadIcon fontSize="large" style={{height: "70%", width: "70%"}}/>
            </Fab>
        </Tooltip>
    </>)

    /*
        return (<>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Main />
                <div style={{
                    width: '2%'
                }} />
                <Contracts onChange={setContract} />
                <div style={{
                    width: '2%'
                }} />
                <ContractServices entitlement={contract} />
            </div>
            {hardwareSupportEntitlement ? <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <HardwareSupportEntitlement />
                <div style={{
                    width: '2%'
                }} />
                <ContractServices entitlement={hardwareSupportEntitlement}/>
            </div> : null}
            
        </>
    )
    */
}
