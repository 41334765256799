import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import Asset from './tables/asset';
import Contracts from './tables/contracts';
import { useDispatch } from 'react-redux';
import { getMaterialDetails } from '../redux/material/actions';
import { getContractDetails } from '../redux/contract/actions';
import SerialNumberReference from './tables/serialNumberReference';
import Order from './tables/order';
import Client from './tables/client';


export default function MaterialDetails() {

    const dispatch = useDispatch();
    const location = useLocation();
    let serialNumber = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    let material = useSelector((state) => {
        let _material = state?.materials?.find(m => m.serial_number === serialNumber);
        if (_material) {
            return JSON.parse(JSON.stringify(_material));
        };
    });
    let orderId = material?.order?.order_id
    let orderLine = material?.order?.order_line
    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!material && !loading && !errors) {
            dispatch(getMaterialDetails(serialNumber, orderId, orderLine));
        };
    }, []);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Asset />
                <div
                    style={{marginLeft: '1%'}}
                />
                <Client />
                <div
                    style={{marginLeft: '1%'}}
                />
                <Order />
                <div
                    style={{marginLeft: '1%'}}
                />
                <SerialNumberReference />
            </div>
            <div
                style={{ marginBottom: '1%' }}
            />
            <Contracts getContractDetails={getContractDetails} />
        </>
    )
};
