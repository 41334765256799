import {
    FILTER_PLACEHOLDER_MATERIALS,
    GET_PLACEHOLDER_MATERIALS,
    GET_PLACEHOLDER_MATERIALS_BULK,
    SORT_PLACEHOLDER_MATERIALS,
} from '../actions';
import {EXPORT_MATERIALS_CONTRACTS_TO_CSV} from '../../material/actions'

import { Auth } from 'aws-amplify';

import { API, graphqlOperation } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import * as queries from '../../../graphql/queries.js';

import {restfulResources, getFromServer} from '../../global/middleware';


let originalFetch = require('isomorphic-fetch');
let fetch = require('fetch-retry')(originalFetch);

API.configure(config)

let questObject;
let bulkResult;
let bulkObjects;
let headers;
let materialsToExport;

export function materialPlaceholderMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            //TODO: Error handling
            switch (action.type){
                case FILTER_PLACEHOLDER_MATERIALS:
                    break;
                case SORT_PLACEHOLDER_MATERIALS:
                    break;
                case GET_PLACEHOLDER_MATERIALS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'placeholderMaterials';

                    bulkResult = await getFromServer(questObject);
                    action.results = bulkResult.results;
                    break;
                // TODO explore this call, why export material_contract
                case GET_PLACEHOLDER_MATERIALS_BULK:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.params.show_entitlements = true;
                    questObject.resource = 'placeholderMaterials';

                    bulkResult = await getFromServer(questObject);
                    bulkObjects = bulkResult.results;
                    headers = [
                        "bundle_master",
                        "bundle_reference",
                        "business_partner_id",
                        "comments",
                        "creation_date",
                        "creation_note",
                        "description",
                        "inactive_reason",
                        "modification_date",
                        "old_order_id",
                        "old_purchase_order",
                        "order_line",
                        "order_id",
                        "purchase_order",
                        "part_number",
                        "placeholder_id",
                        "serial_number",
                        "shipping_date",
                        "sn_type",
                        "sold_to",
                        "status",
                        "support_partner",
                        "type"
                    ];

                    materialsToExport = headers.join(",") + "\n";

                    for (let material of bulkObjects) {
                        let newMaterialArray = [
                            material?.bundle_master,
                            material?.bundle_reference,
                            material?.business_partner_id,
                            material?.comments,
                            material?.creation_date,
                            material?.creation_note,
                            material?.description,
                            material?.inactive_reason,
                            material?.modification_date,
                            material?.old_order_id,
                            material?.old_purchase_order,
                            material?.order?.order_line,
                            material?.order?.order_id,
                            material?.order?.purchase_order,
                            material?.part_number,
                            material?.placeholder_id,
                            material?.serial_number,
                            material?.shipping_date,
                            material?.sn_type,
                            material?.sold_to?.applicant_id,
                            material?.status,
                            material?.support_partner?.company_id,
                            material?.type
                        ];
                        materialsToExport = materialsToExport + newMaterialArray.join(",") + "\n";
                    };
                    dispatch({ type: EXPORT_MATERIALS_CONTRACTS_TO_CSV, data: { materials: bulkObjects, type: 'placeholder' } });
                    action.CSVMaterials = materialsToExport;
                    break;
                };
            return next(action);

        }
    }
}