import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
} from "react-router-dom";
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { Accordion, AccordionDetails, Typography, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Systems(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    let contractReference = location.pathname.split("/")[location.pathname.split("/").length - 2];
    let contractType = location.pathname.split("/")[location.pathname.split("/").length - 1].replace(/%20/g, " ");

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    let contract = useSelector((state) => {
        let _contract = state?.contracts?.find(c => c.contract_reference === contractReference && c.type === contractType);
        if (_contract) {
            return JSON.parse(JSON.stringify(_contract));
        } else return {}
    });

    const systems = contract?.entitlements?.filter(m => m.__typename === 'System');

    const nameRows = [
        { name: 'System ID' },
        { name: 'Creation Date' },
        { name: 'Modification Date' },
        { name: 'Order ID' },
        { name: 'Entitlement Start Date' },
        { name: 'Entitlement End Date' },
        { name: 'Entitlement Status' },
    ];

    function handleSelectSystem(systemId) {
        dispatch(props.getSystemDetails(systemId));
        navigate('/systems/details/' + systemId);
    };

    const pendingSystems = systems?.filter(system => system.status?.toLowerCase() === 'pending') || [];
    const activeSystems = systems?.filter(system => system.status?.toLowerCase() === 'active') || [];

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <WebAssetIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Systems
                            </Typography>
                        </AccordionSummary>
                        <Typography>
                            Total systems: {systems?.length ?? 0}, of which pending: {pendingSystems.length}, and active: {activeSystems.length}
                        </Typography>
                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="contracts table">
                                <TableRow>
                                    {nameRows?.map((row) => {
                                        return (
                                            <TableCell component="th" scope="row" align="center" style={{ fontWeight: "bolder" }}>
                                                {row.name}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                                {systems?.map((system) => {
                                    const valueRows = [
                                        { value: system?.system_id },
                                        { value: system?.creation_date },
                                        { value: system?.modification_date },
                                        { value: system?.order?.order_id },
                                        { value: system?.entitlement_start_date },
                                        { value: system?.entitlement_end_date },
                                        { value: system?.status },
                                    ];
                                    return (
                                        <>
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                hover
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => { handleSelectSystem(system?.system_id) }}

                                            >

                                                {
                                                    valueRows?.map((row, i) => {
                                                        return (

                                                            <TableCell align="center" style={{ position: 'relative' }}>
                                                                {row.value}
                                                            </TableCell>

                                                        )
                                                    })
                                                }

                                            </TableRow>
                                        </>
                                    )
                                })}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}