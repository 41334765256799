import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { useSelector } from 'react-redux';
import {
    exportOrdersToCSV,
    filterOrders,
    getOrders,
    sortOrders,
} from '../redux/dynamo_db/actions';
import {
    setParameters,
    removeParameter,
    clearParameters
} from '../redux/global/actions';
import {
    useNavigate
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import MultiValueInput from '../inputs/multiValueInput';
import { Fab, Grid, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect } from 'react';
import './Contracts.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import './Orders.css'


export default function Orders() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const queryParameters = useSelector(state => state?.parameters?.orders);

    const [currentOrderId, setCurrentOrderId] = React.useState();
    const [currentOrderLine, setCurrentOrderLine] = React.useState();

    const [filterOrderId, setFilterOrderId] = React.useState([]);
    const [filterOrderLine, setFilterOrderLine] = React.useState([]);
    const [filterSoldTo, setFilterSoldTo] = React.useState([]);
    const [filterPartNumber, setFilterPartNumber] = React.useState([]);
    const [filterPartDescription, setFilterPartDescription] = React.useState([]);

    const handleExecuteSearch = () => {
        let queryObject = {}
        if (queryParameters?.orderIds) {
            queryObject.order_id = queryParameters?.orderIds;
        };
        if (queryParameters?.orderLines) {
            queryObject.order_line = queryParameters?.orderLines;
        };
        dispatch(getOrders(queryObject));
    };

    const handleExecuteFilter = () => {
        let filterObject = {};
        if (filterOrderId?.length > 0) {
            filterObject.orderId = [filterOrderId];
        };
        if (filterOrderLine?.length > 0) {
            filterObject.orderLine = [filterOrderLine];
        };
        if (filterSoldTo?.length > 0) {
            filterObject.soldTo = [filterSoldTo];
        };
        if (filterPartNumber?.length > 0) {
            filterObject.partNumber = [filterPartNumber];
        };
        if (filterPartDescription?.length > 0) {
            filterObject.partDescription = [filterPartDescription];
        };
        dispatch(filterOrders(filterObject));
    };

    useEffect(() => {
        handleExecuteFilter()
    }, [
        filterOrderId,
        filterOrderLine,
        filterSoldTo,
        filterPartNumber,
        filterPartDescription
    ]);

    function handleSelectOrder(orderId, orderLine) {
        navigate('/orders/details/' + orderId + '/' + orderLine);
    };

    const handleDeleteOrderID = (chipToDelete) => () => {
        dispatch(removeParameter({ orders: { orderIds: [chipToDelete] } }));
    };
    const handleClearOrderIds = () => {
        dispatch(clearParameters({ orders: 'orderIds' }))
    };
    const handleDeleteOrderLine = (chipToDelete) => () => {
        dispatch(removeParameter({ orders: { orderLines: [chipToDelete] } }));
    };
    const handleDeleteOrderLines = () => {
        dispatch(clearParameters({ orders: 'orderLines' }))
    };

    const orders = useSelector(state => {
        if (state.filteredOrders) {
            return state.filteredOrders.map((order) => {
                return {
                    order_id: order.order_id,
                    order_line: order.order_line,
                    sold_to: order?.sold_to,
                    part_number: order?.part_number,
                    part_description: order?.part_description,
                    order_date: order?.order_date
                };
            });
        }
        else return [];
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(document.getElementById("menuAnchor"));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterDescription = 'Add filters to the list of results, and export the filtered list to CSV (currently not implemented for orders)';


    const [sortDirection, setSortDirection] = React.useState('');
    const [sortParameter, setSortParameter] = React.useState('');

    const handleSortOrders = (sortParameter) => {
        setSortParameter(sortParameter);
        if (sortDirection === '') {
            setSortDirection('ascending');
            dispatch(sortOrders(sortParameter, 'ascending'));
        } else if (sortDirection === 'ascending') {
            setSortDirection('descending');
            dispatch(sortOrders(sortParameter, 'descending'));
        } else {
            setSortDirection('ascending');
            dispatch(sortOrders(sortParameter, 'ascending'));
        };
    };

    const handleKeydown = (event) => {
        if (event.key == 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            setTimeout(() => {
                handleExecuteSearch();
            }, 0);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    const handleExportToCSV = (orders) => {
        dispatch(exportOrdersToCSV(orders))
    };

    return (
        <React.Fragment>
            <MultiValueInput
                setCurrentParam={setCurrentOrderId}
                setQueryParams={setParameters}
                currentParam={currentOrderId}
                queryParams={queryParameters?.orderIds}
                handleDelete={handleDeleteOrderID}
                handleClear={handleClearOrderIds}
                label="Order ID"
                family='orders'
                type='orderIds'
            />
            <MultiValueInput
                setCurrentParam={setCurrentOrderLine}
                setQueryParams={setParameters}
                currentParam={currentOrderLine}
                queryParams={queryParameters?.orderLines}
                handleDelete={handleDeleteOrderLine}
                handleClear={handleDeleteOrderLines}
                label="Order Line"
                family='orders'
                type='orderLines'
            />
            <Grid
                container
                style={{
                    justifyContent: 'center'
                }}
            >
                <Grid item>
                    <Button variant="contained" onClick={handleExecuteSearch} style={{ width: '15vw' }}>Search</Button>
                </Grid>
            </Grid>
            <Title>Orders</Title>
            <Typography>
                {orders?.length ?
                    orders?.length === 100 ?
                        orders?.length + ' results, use bulk export for more' :
                        orders?.length + ' results' :
                    null
                }
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Order ID"
                                        variant='standard'
                                        onChange={(e) => { setFilterOrderId(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortOrders('order_id')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'order_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'order_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Order Line"
                                        variant='standard'
                                        onChange={(e) => { setFilterOrderLine(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortOrders('order_line')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'order_line' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'order_line' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Sold To"
                                        variant='standard'
                                        onChange={(e) => { setFilterSoldTo(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortOrders('sold_to')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'sold_to' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'sold_to' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Part Number"
                                        variant='standard'
                                        onChange={(e) => { setFilterPartNumber(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortOrders('part_number')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'part_number' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'part_number' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Part Description"
                                        variant='standard'
                                        onChange={(e) => { setFilterPartDescription(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortOrders('part_description')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'part_description' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'part_description' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    Order Date
                                </Grid>
                                <Grid
                                    item
                                    onClick={() => handleSortOrders('order_date')}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'order_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'order_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((row, i) => (
                        <TableRow
                            hover
                            key={i + row?.order_id}
                            onClick={() => handleSelectOrder(row?.order_id, row?.order_line)}
                            style={{
                                cursor: 'pointer'
                            }}>
                            <TableCell>{row?.order_id}</TableCell>
                            <TableCell>{row?.order_line}</TableCell>
                            <TableCell>{row?.sold_to}</TableCell>
                            <TableCell>{row?.part_number}</TableCell>
                            <TableCell>{row?.part_description}</TableCell>
                            <TableCell>{row?.order_date}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Tooltip
                title={<Typography fontSize={17}>{filterDescription}</Typography>}
            >
                <Fab
                    style={{
                        position: 'absolute',
                        right: 10,
                        bottom: 10,
                        backgroundColor: "#6b469e",
                        color: "white",
                        height: "5em",
                        width: "5em"
                    }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <div
                        id="menuAnchor"
                        style={{
                            position: 'relative',
                            bottom: 200,
                            right: 250
                        }}
                    >
                    </div>
                    <FileDownloadIcon fontSize="large" style={{ height: "70%", width: "70%" }} />
                </Fab>
            </Tooltip>
            <div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem >
                        <TextField
                            label="Order ID"
                            onChange={(e) => { setFilterOrderId(e.target.value); }}
                            onKeyDown={(e) => { e.stopPropagation(); }}
                        />
                    </MenuItem>
                    <MenuItem >
                        <TextField
                            label="Order Line"
                            onChange={(e) => { setFilterOrderLine(e.target.value); }}
                            onKeyDown={(e) => { e.stopPropagation(); }}
                        />
                    </MenuItem>
                    <MenuItem >
                        <Button variant="contained" onClick={() => {
                            handleExportToCSV(orders)
                        }}>Export orders</Button>
                    </MenuItem>
                </Menu>
            </div>
        </React.Fragment>
    );
};