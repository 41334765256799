export const GET_SYSTEMS = 'GET_SYSTEMS';
export const GET_SYSTEM_DETAILS = 'GET_SYSTEM_DETAILS';
export const FILTER_SYSTEMS = 'FILTER_SYSTEMS';
export const SORT_SYSTEMS = 'SORT_SYSTEMS';
export const EXPORT_SYSTEMS_TO_CSV = 'EXPORT_SYSTEMS_TO_CSV';


export function getSystems(queryParameters) {
    console.log("Action GET_SYSTEMS", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_SYSTEMS, data: queryParameters };
};

export function getSystemDetails(systemId) {
    console.log("Action GET_SYSTEM_DETAILS");
    return {
        type: GET_SYSTEM_DETAILS, data: {
            system_id: systemId,
            show_entitlements: true
        }
    };
};

export function filterSystems(filterParameters) {
    console.log("Action FILTER_SYSTEMS", filterParameters);
    return { type: FILTER_SYSTEMS, data: filterParameters };
};


export function sortSystems(sortParameter, direction) {
    console.log("Action SORT_SYSTEMS");
    return { type: SORT_SYSTEMS, data: { sortParameter, direction } };
};

export function exportSystemsToCSV(systems) {
    console.log("Action EXPORT_SYSTEMS_TO_CSV");
    return { type: EXPORT_SYSTEMS_TO_CSV, systems };
};
