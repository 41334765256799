import jwt_decode from 'jwt-decode';



function getItemFromLocalStorage() {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Check if the key starts with "CognitoIdentityServiceProvider" and ends with "accessToken"
    if (
      key.startsWith("CognitoIdentityServiceProvider") &&
      key.endsWith("accessToken")
    ) {
      const item = localStorage.getItem(key);
      return item;
    }
  }

  // Return null if no matching item is found
  return null;
}

export default function getUserProfiles() {
  const accessTokenItem = getItemFromLocalStorage();
  if (accessTokenItem) {
    const decodedToken = jwt_decode(accessTokenItem);
    return decodedToken["cognito:groups"];

  } else {
    console.log("Access token item not found in localStorage");
    return null;
  }
}