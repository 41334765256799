import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import AlertModal from '../widgets/alertModal';
import Asset from './tables/asset';
import Client from './tables/client';
import Order from './tables/order';
import { getContractDetails } from '../redux/contract/actions';
import Contracts from './tables/contracts';


export default function LicenseDetails() {

    const location = useLocation();
    let licenseId = location.pathname.split("/")[location.pathname.split("/").length - 2];

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    let license = useSelector((state) => {
        let _license = state?.licenses?.find(l => l.license_identifier === licenseId);
        if (_license) {
            return JSON.parse(JSON.stringify(_license));
        } else return {}
    });

    return (<>
        <AlertModal />
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '1%'
            }}
        >
            <Asset />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Client />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Order />
            <div style={{ marginBottom: '1%' }} />
        </div>
        <Contracts getContractDetails={getContractDetails} />
    </>)
}
