export const GET_ORDERS = 'GET_ORDERS';
export const FILTER_ORDERS = 'FILTER_ORDERS';
export const FILTER_SERIAL_NUMBERS = 'FILTER_SERIAL_NUMBERS';
export const GET_SERIAL_NUMBERS = 'GET_SERIAL_NUMBERS';
export const SORT_SERIAL_NUMBERS = 'SORT_SERIAL_NUMBERS';
export const SORT_ORDERS = 'SORT_ORDERS';
export const EXPORT_SERIAL_NUMBERS_TO_CSV = 'EXPORT_SERIAL_NUMBERS_TO_CSV';
export const EXPORT_ORDERS_TO_CSV = 'EXPORT_ORDERS_TO_CSV';

export function filterOrders(filterParameters) {
    console.log("Action FILTER_ORDERS", filterParameters);
    return { type: FILTER_ORDERS, data: filterParameters };
};

export function getOrders(queryParameters) {
    console.log("Action GET_ORDERS", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_ORDERS, data: queryParameters };
};

export function sortSerialNumbers(sortParameter, direction) {
    console.log("Action SORT_SERIAL_NUMBERS");
    return { type: SORT_SERIAL_NUMBERS, data: { sortParameter, direction } };
};

export function sortOrders(sortParameter, direction) {
    console.log("Action SORT_ORDERS");
    return { type: SORT_ORDERS, data: { sortParameter, direction } };
};

export function getSerialNumbers(queryParameters) {
    console.log("Action GET_SERIAL_NUMBERS", queryParameters);
    return { type: GET_SERIAL_NUMBERS, data: queryParameters };
};

export function filterSerialNumbers(filterParameters) {
    console.log("Action FILTER_SERIAL_NUMBERS", filterParameters);
    return { type: FILTER_SERIAL_NUMBERS, data: filterParameters };
};

export function exportSerialNumbersToCSV(serialNumbers) {
    console.log("Action EXPORT_SERIAL_NUMBERS_TO_CSV", serialNumbers);
    return { type: EXPORT_SERIAL_NUMBERS_TO_CSV, serialNumbers };
};

export function exportOrdersToCSV(orders) {
    console.log("Action EXPORT_ORDERS_TO_CSV", orders);
    return { type: EXPORT_ORDERS_TO_CSV, orders };
};
