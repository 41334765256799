import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function ContractServices(props) {

    const services = props?.entitlement?.services;

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: '30%' }} size="small" aria-label="contracts table" >
                        <TableRow>
                            <TableCell
                                style={{
                                    backgroundColor: "#6b469e",
                                    color: "white",
                                }}
                                colSpan="100%"
                            >
                                Services
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                Type
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                Business Hours
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                Response SLA
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                Level
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                Shipment SLA
                            </TableCell>
                        </TableRow>
                        {services?.map((service, i) => {
                            return (
                                <TableRow hover>
                                    <TableCell
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        key={service.type + i}
                                    >
                                        {service.type}
                                    </TableCell>
                                    <TableCell align="center">{service?.business_hours}</TableCell>
                                    <TableCell align="center">{service?.response_sla}</TableCell>
                                    <TableCell align="center">{service?.level}</TableCell>
                                    <TableCell align="center">{service?.shipment_sla}</TableCell>
                                </TableRow>

                            )
                        })}
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}