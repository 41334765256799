import {
    GET_SYSTEMS,
    GET_SYSTEM_DETAILS,
    FILTER_SYSTEMS,
    SORT_SYSTEMS,
    EXPORT_SYSTEMS_TO_CSV,
} from '../actions';

import { API } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import {getFromServer} from '../../global/middleware';

API.configure(config)

let questObject;
let getResult;
let systemsToExport;
let headers;

export function systemMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            //TODO: Error handling
            switch (action.type){
                case FILTER_SYSTEMS:
                    break;
                case SORT_SYSTEMS:
                    break;
                case GET_SYSTEMS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'systems';

                    getResult = await getFromServer(questObject);
                    if (getResult.responseCode < 202 && getResult !== 0) {
                        action.results = getResult.results;
                    } else {
                        action.errors = { responseCode: getResult.responseCode };
                        console.log('action.errors', action.errors, 'getResult', getResult);
                    };
                    break;
                case GET_SYSTEM_DETAILS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'systems';

                    getResult = await getFromServer(questObject);
                    if (getResult.responseCode < 202 && getResult !== 0) {
                        action.results = getResult.results;
                    } else {
                        action.errors = { responseCode: getResult.responseCode };
                        console.log('action.errors', action.errors, 'getResult', getResult);
                    };
                    break;
                case EXPORT_SYSTEMS_TO_CSV:
                        headers = [
                            "system_id",
                            "business_partner",
                            "cpu_id1",
                            "cpu_id2",
                            "creation_date",
                            "creation_note",
                            "description",
                            "inactive_reason",
                            "modification_date",
                            "name",
                            "order_id",
                            "purchase_order",
                            "part_number",
                            "product",
                            "release",
                            "sold_to",
                            "status",
                            "support_partner"
                        ];
                    
                        systemsToExport = headers.join(",") + "\n";
                    
                        for (let system of action?.systems) {
                            let newSystemsArray = [
                                system?.system_id,
                                system?.business_partner,
                                system?.cpu_id1,
                                system?.cpu_id2,
                                system?.creation_date,
                                system?.creation_note,
                                system?.description,
                                system?.inactive_reason,
                                system?.modification_date,
                                system?.name,
                                system?.order?.order_id,
                                system?.order?.purchase_order,
                                system?.part_number,
                                system?.product,
                                system?.release,
                                system?.sold_to,
                                system?.status,
                                system?.support_partner
                            ];
                    
                            systemsToExport = systemsToExport + newSystemsArray.join(",") + "\n";
                        };
                        action.CSVSystems = systemsToExport;
                        break;
                };
            return next(action);

        }
    }
}