import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function Preferences(props) {
    const headerRef = props.headerRef;

    const handleGetUserPreferences = () => {
        headerRef.current.getUserPreferences()
            .then(preferences => {
                console.log("User Preferences: ", preferences);
            })
            .catch(error => {
                console.error('Error fetching user preferences:', error);
            });
    };

    const handleUpdateUserPreferences = (appId, newPreferences) => {
        headerRef.current.updateUserPreferences(appId, newPreferences);
    };

    console.log("App component rendering");

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
            <Button variant="contained" color="primary" onClick={handleGetUserPreferences}>
                Get User Preferences
            </Button>
            <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => handleUpdateUserPreferences('argos', { 'new': 'preferences' })}
                style={{ marginTop: '10px' }}
            >
                Update User Preferences
            </Button>
        </Box>
    );
}
