import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import Asset from './tables/asset';
import Contracts from './tables/contracts';
import { useDispatch } from 'react-redux';
import { getMaterialDetails, getCandidateMaterials} from '../redux/material/actions';
import { getContractDetails} from '../redux/contract/actions';
import Order from './tables/order';
import Client from './tables/client';
import InitialOrder from './tables/initialOrder';
import { Grid } from '@mui/material';
import Candidates from './tables/candidates';


export default function PlaceholderMaterialDetails() {

    const dispatch = useDispatch();
    const location = useLocation();
    let serialNumber = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    let material = useSelector((state) => {
        let _material = state?.placeholderMaterials?.find(m => m.serial_number === serialNumber);
        if (_material) {
            return JSON.parse(JSON.stringify(_material));
        };
    });
    let orderId = material?.order?.order_id
    let orderLine = material?.order?.order_line
    const candidateMaterials = useSelector(state => state.potentialCandidates);

    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!loading && !errors) {
            if (!material) {
                dispatch(getMaterialDetails(serialNumber, orderId, orderLine));
            };
        };
    }, []);

    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!candidateMaterials) {
            dispatch(getCandidateMaterials({ order: orderId }));
        }
    }, [orderId]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Asset />
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Grid container flexDirection={'column'}>
                    <Grid item>
                        <Client />
                    </Grid>
                    <div
                        style={{ marginTop: '1%' }}
                    />
                    <Grid item>
                        <Candidates />
                    </Grid>
                </Grid>
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Grid container flexDirection={'column'}>
                    <Grid item>
                        <Order />
                    </Grid>
                    <div
                        style={{ marginTop: '1%' }}
                    />
                    <Grid item>
                        <InitialOrder />
                    </Grid>
                </Grid>
            </div>
            <div
                style={{ marginBottom: '1%' }}
            />
            <Contracts getContractDetails={getContractDetails} />
        </>
    )
};
