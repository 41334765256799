import * as React from 'react';
import { Typography, List, ListItem } from '@mui/material';

export default function Fallback() {

    return (
        <React.Fragment>
            <Typography variant='h3'>
                You do not have the rights to view that page
            </Typography>
        </React.Fragment>
    );
};