import {
    GET_LICENSES,
    GET_LICENSE_DETAILS,
    SORT_LICENSES,
    FILTER_LICENSES
} from '../actions';

import { ERROR } from '../../global/actions'
import { API } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import { getFromServer } from '../../global/middleware';

let originalFetch = require('isomorphic-fetch');
let fetch = require('fetch-retry')(originalFetch);

API.configure(config)

async function getLicenseDetailsFromServer(action) {
    let questObject = {};
    questObject.params = Object.assign({}, action.data);

    questObject.params.show_entitlements = true;
    questObject.params.show_parents = true;
    questObject.params.show_children = true;

    questObject.resource = 'licenses';

    let getResult = await getFromServer(questObject);
    if (getResult.responseCode < 202 && getResult !== 0) {
        let result = getResult.results;
        console.log('getLicenseDetailsFromServer', result)
        return result;
    } else {
        throw new Error()
    };
};

let questObject;
let getResult;
export function licenseMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            //TODO: Error handling
            switch (action.type) {
                case FILTER_LICENSES:
                    break;
                case GET_LICENSES:
                    console.log('getting licenses')
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'licenses';

                    getResult = await getFromServer(questObject);
                    if (getResult.responseCode < 202 && getResult !== 0) {
                        action.results = getResult.results.map((result) => ({
                            ...result,
                        }));
                    } else {
                        action.errors = { responseCode: getResult.responseCode };
                        console.log('action.errors', action.errors, 'getResult', getResult);
                    };
                    break;
                case GET_LICENSE_DETAILS:
                    try {
                        let licenseDetails = await getLicenseDetailsFromServer(action);
                        action.results = licenseDetails;
                    } catch (err) {
                        dispatch({ type: ERROR });
                    };
                    break;
                case SORT_LICENSES:
                    break;
            };
            return next(action);

        }
    }
}