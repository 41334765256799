import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Accordion, AccordionDetails, Typography, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SystemApplication() {

    const location = useLocation();
    let applicationId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const systemApplication = useSelector((state) => {
        let _systemApplication = state?.systemApplications?.find(s => s.application_id === applicationId);
        if (_systemApplication) {
            return JSON.parse(JSON.stringify(_systemApplication));
        } else return {}
    });
    
    const rows = [
        { name: 'Application ID', value: systemApplication?.application_id },
        { name: 'Application Family', value: systemApplication?.application_family },
        { name: 'Business Partner', value: systemApplication?.business_partner?.company_id },
        { name: 'Comments', value: systemApplication?.comments },
        { name: 'Creation Date', value: systemApplication?.creation_date },
        { name: 'Creation Note', value: systemApplication?.creation_note },
        { name: 'Description', value: systemApplication?.description },
        { name: 'End User', value: systemApplication?.end_user?.company_id },
        { name: 'Inactive Reason', value: systemApplication?.inactive_reason },
        { name: 'Modification Date', value: systemApplication?.modification_date },
        { name: 'Order ID', value: systemApplication?.order?.order_id },
        { name: 'Part Number', value: systemApplication?.part_number },
        { name: 'Product', value: systemApplication?.product },
        { name: 'Sold To', value: systemApplication?.sold_to?.applicant_id },
        { name: 'Support Partner', value: systemApplication?.support_partner?.company_id },
        { name: 'Type', value: systemApplication?.type }
    ];

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <ReceiptIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                SystemApplication
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="serial number reference table">
                                <TableBody>
                                    {rows.map((row, i) => {
                                        return (
                                            <TableRow key={row.name + i + "row"}>
                                                <TableCell key={row.name + i + " namecell"}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell key={row.value + i + " valuecell"}>
                                                    {row.value}
                                                </TableCell>
                                            </TableRow>
                                        )

                                    })}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}