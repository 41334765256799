import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import { useSelector } from 'react-redux';
import {
  setParameters,
  removeParameter,
  clearParameters,
} from '../../redux/global/actions';
import {
  getSerialNumbers,
  sortSerialNumbers,
  filterSerialNumbers as filterSNs,
  exportSerialNumbersToCSV
} from '../../redux/dynamo_db/actions';
import {
  useNavigate,
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import MultiValueInput from '../../inputs/multiValueInput';
import DateInput from '../../inputs/dateInput';
import { Accordion, AccordionDetails, AccordionSummary, Fab, Grid, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import SerialNumberChildren from './serialNumberChildren';

export default function SerialNumbers(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParameters = useSelector(state => state?.parameters?.serialNumbers);

  const [currentSerialNumber, setCurrentSerialNumber] = React.useState();
  const [currentBundleReference, setCurrentBundleReference] = React.useState();

  const [filterSerialNumbers, setFilterSerialNumbers] = React.useState([]);
  const [filterPartNumbers, setFilterPartNumbers] = React.useState([]);
  const [filterSoldTos, setFilterSoldTos] = React.useState([]);
  const [filterBusinessPartners, setFilterBusinessPartners] = React.useState([]);
  const [filterShippingDateStart, setFilterShippingDateStart] = React.useState();
  const [filterShippingDateEnd, setFilterShippingDateEnd] = React.useState();
  const [filterExtractionDateStart, setFilterExtractionDateStart] = React.useState();
  const [filterExtractionDateEnd, setFilterExtractionDateEnd] = React.useState();
  const [filterOrderId, setFilterOrderId] = React.useState([]);
  const [filterOrderLine, setFilterOrderLine] = React.useState([]);

  const handleExecuteSearch = () => {
    let queryObject = {}
    if (queryParameters?.serialNumbers) {
      queryObject.serial_number = queryParameters?.serialNumbers;
    };
    if (queryParameters?.bundleReference) {
      queryObject.bundle_reference = queryParameters?.bundleReference;
    };
    dispatch(getSerialNumbers(queryObject));
  };


  const handleExecuteFilter = () => {
    let filterObject = {}
    if (filterSerialNumbers?.length > 0) {
      filterObject.serialNumbers = [filterSerialNumbers];
    };
    if (filterBusinessPartners?.length > 0) {
      filterObject.businessPartners = [filterBusinessPartners];
    };
    if (filterSoldTos?.length > 0) {
      filterObject.soldTos = [filterSoldTos];
    };
    if (filterPartNumbers?.length > 0) {
      filterObject.partNumbers = [filterPartNumbers];
    };
    if (filterOrderId?.length > 0) {
      filterObject.orderId = [filterOrderId];
    };
    if (filterOrderLine?.length > 0) {
      filterObject.orderLine = [filterOrderLine];
    };
    if (filterShippingDateStart) {
      filterObject.filterShippingDateStart = filterShippingDateStart;
    };
    if (filterShippingDateEnd) {
      filterObject.filterCreationDateEnd = filterShippingDateEnd;
    };
    if (filterExtractionDateStart) {
      filterObject.filterExtractionDateStart = filterExtractionDateStart;
    };
    if (filterExtractionDateEnd) {
      filterObject.filterExtractionDateEnd = filterExtractionDateEnd;
    };
    console.log('filterObject', filterObject)
    dispatch(filterSNs(filterObject));
  };


  useEffect(() => {
    handleExecuteFilter()
  }, [
    filterSerialNumbers,
    filterPartNumbers,
    filterSoldTos,
    filterOrderId,
    filterOrderLine,
    filterShippingDateStart,
    filterShippingDateEnd,
    filterExtractionDateStart,
    filterExtractionDateEnd
  ]);

  function handleSelectMaterial(serialNumber) {
    navigate('/serialnumbers/details/' + serialNumber);
  };

  const handleDeleteSerialNumber = (chipToDelete) => () => {
    dispatch(removeParameter({ serialNumbers: { serialNumbers: [chipToDelete] } }));
  };

  const handleClearSerialNumbers = () => {
    dispatch(clearParameters({ serialNumbers: 'serialNumbers' }));
  };

  const handleDeleteBundleReference = (chipToDelete) => () => {
    dispatch(removeParameter({ serialNumbers: { bundleReference: [chipToDelete] } }));
  };

  const handleClearBundleReference = () => {
    dispatch(clearParameters({ serialNumbers: 'bundleReference' }));
  };

  const materials = useSelector(state => {
    if (state.filteredSerialNumbers) {
      return state.filteredSerialNumbers.map((serialNumber) => {
        return {
          part_number: serialNumber.part_number,
          serial_number: serialNumber.serial_number,
          shipping_date: serialNumber.shipping_date,
          extract_date: serialNumber.extract_date,
          sold_to: serialNumber.sold_to,
          order_id: serialNumber.order_id,
          order_line: serialNumber.order_line
        }
      })
    }
    else return []
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(document.getElementById("menuAnchor"));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterDescription = 'Add filters to the list of results, and export the filtered list to CSV';

  const [sortDirection, setSortDirection] = React.useState('');
  const [sortParameter, setSortParameter] = React.useState('');

  const handleSortSerialNumbers = (sortParameter) => {
    setSortParameter(sortParameter);
    if (sortDirection === '') {
      setSortDirection('ascending');
      dispatch(sortSerialNumbers(sortParameter, 'ascending'));
    } else if (sortDirection === 'ascending') {
      setSortDirection('descending');
      dispatch(sortSerialNumbers(sortParameter, 'descending'));
    } else {
      setSortDirection('ascending');
      dispatch(sortSerialNumbers(sortParameter, 'ascending'));
    };
  };

  // const [openBulkConfirmation, setOpenBulkConfirmation] = React.useState(false);
  const [expanded, setExpanded] = React.useState(null);

  const handleKeydown = (event) => {
    if (event.key == 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      setTimeout(() => {
        handleExecuteSearch();
      }, 0);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const handleExportToCSV = (materials) => {
    dispatch(exportSerialNumbersToCSV(materials))
};

  return (
    <React.Fragment>
      <MultiValueInput
        setCurrentParam={setCurrentSerialNumber}
        setQueryParams={setParameters}
        currentParam={currentSerialNumber}
        queryParams={queryParameters?.serialNumbers}
        handleDelete={handleDeleteSerialNumber}
        handleClear={handleClearSerialNumbers}
        label="Serial Number"
        family='serialNumbers'
        type='serialNumbers'
        disabled={queryParameters?.bundleReference?.length > 0 ? true : false}
      />
      <MultiValueInput
        setCurrentParam={setCurrentBundleReference}
        setQueryParams={setParameters}
        currentParam={currentBundleReference}
        queryParams={queryParameters?.bundleReference}
        handleDelete={handleDeleteBundleReference}
        handleClear={handleClearBundleReference}
        label="Bundle Reference"
        family='serialNumbers'
        type='bundleReference'
        disabled={queryParameters?.serialNumbers?.length > 0 ? true : false}
      />
      <Grid
        container
        style={{
          justifyContent: 'center'
        }}
      >
        <Grid item>
          <Button variant="contained" onClick={handleExecuteSearch} style={{ width: '15vw' }}>Search</Button>
        </Grid>
      </Grid>
      <Title>Materials</Title>
      <Typography>
        {
          materials?.length ? materials?.length + ' results' : null
        }
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Serial Number"
                    variant='standard'
                    onChange={(e) => { setFilterSerialNumbers(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}

                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSerialNumbers('serial_number')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'serial_number' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'serial_number' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>

            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Part Number"
                    variant='standard'
                    onChange={(e) => { setFilterPartNumbers(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSerialNumbers('part_number')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'part_number' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'part_number' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>

            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  Shipping Date
                </Grid>
                <Grid
                  item
                  onClick={() => handleSortSerialNumbers('shipping_date')}
                >
                  {sortDirection === 'ascending' && sortParameter === 'shipping_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'shipping_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>

            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  Extract Date
                </Grid>
                <Grid
                  item
                  onClick={() => handleSortSerialNumbers('extract_date')}
                >
                  {sortDirection === 'ascending' && sortParameter === 'extract_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'extract_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>

            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Sold To"
                    variant='standard'
                    onChange={(e) => { setFilterSoldTos(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSerialNumbers('sold_to')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'sold_to' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'sold_to' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>

            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Order ID"
                    variant='standard'
                    onChange={(e) => { setFilterOrderId(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSerialNumbers('order_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'order_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'order_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>

            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Order Line"
                    variant='standard'
                    onChange={(e) => { setFilterOrderLine(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSerialNumbers('order_line')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'order_line' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'order_line' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {materials.map((row, i) => (
            <React.Fragment
              key={row?.serial_number + i}
            >
              <TableRow
                hover
                onClick={() => { handleSelectMaterial(row?.serial_number, row?.order_id, row?.order_line) }}
                style={{
                  cursor: 'pointer'
                }}>
                <TableCell>{row?.serial_number}</TableCell>
                <TableCell>{row?.part_number}</TableCell>
                <TableCell>{row?.shipping_date}</TableCell>
                <TableCell>{row?.extract_date}</TableCell>
                <TableCell>{row?.sold_to}</TableCell>
                <TableCell>{row?.order_id}</TableCell>
                <TableCell>{row?.order_line}</TableCell>
              </TableRow>
              <Accordion
                expanded={expanded === row?.serial_number}
              >
                <AccordionSummary style={{ display: 'none' }} />
                <AccordionDetails>
                  <SerialNumberChildren material={row} />
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      <Tooltip
        title={<Typography fontSize={17}>{filterDescription}</Typography>}
      >
        <Fab
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            backgroundColor: "#6b469e",
            color: "white",
            height: "5em",
            width: "5em"
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <div
            id="menuAnchor"
            style={{
              position: 'relative',
              bottom: 500,
              right: 250
            }}
          >
          </div>
          <FileDownloadIcon fontSize="large" style={{ height: "70%", width: "70%" }} />
        </Fab>
      </Tooltip>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem >
            <TextField
              label="Serial Number"
              onChange={(e) => { setFilterSerialNumbers(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <Grid>
              <div>
                Shipping Date
              </div>
              <DateInput
                setFilterDateStart={setFilterShippingDateStart}
                setFilterDateEnd={setFilterShippingDateEnd}
              />
            </Grid>
          </MenuItem>
          <MenuItem >
            <Grid>
              <div>
                Extraction Date
              </div>
              <DateInput
                setFilterDateStart={setFilterExtractionDateStart}
                setFilterDateEnd={setFilterExtractionDateEnd}
              />
            </Grid>
          </MenuItem>
          <MenuItem >
            <TextField
              label="Part Number"
              onChange={(e) => { setFilterPartNumbers(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <TextField
              label="Sold To"
              onChange={(e) => { setFilterSoldTos(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <TextField
              label="Order ID"
              onChange={(e) => { setFilterOrderId(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <TextField
              label="Order Line"
              onChange={(e) => { setFilterOrderLine(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <Button variant="contained" onClick={() => {
              handleExportToCSV(materials)
            }}>Export materials</Button>
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment >
  );
}
