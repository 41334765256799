import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { useSelector } from 'react-redux';
import {
  setParameters,
  removeParameter,
  clearParameters,
} from '../redux/global/actions';
import {
  getSystemApplicationDetails,
  getSystemApplications,
  filterSystemApplications,
  sortSystemApplications
} from '../redux/application/actions';
import {
  useNavigate,
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import MultiValueInput from '../inputs/multiValueInput';
import DateInput from '../inputs/dateInput';
import { Accordion, AccordionDetails, AccordionSummary, Fab, Grid, IconButton, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect } from 'react';
import './SystemApplications.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import SearchDateInput from '../inputs/searchDateInput';

export default function SystemApplications(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParameters = useSelector(state => state?.parameters?.systemApplications);

  const [currentApplicationId, setCurrentApplicationId] = React.useState();
  const [currentBusinessPartner, setCurrentBusinessPartner] = React.useState();
  const [currentSoldTo, setCurrentSoldTo] = React.useState();
  const [currentOrder, setCurrentOrder] = React.useState();
  const [currentEndUser, setCurrentEndUser] = React.useState()
  const [currentSupportPartner, setCurrentSupportPartner] = React.useState();

  const [filterApplicationIds, setFilterApplicationIds] = React.useState([]);
  const [filterSoldTos, setFilterSoldTos] = React.useState([]);
  const [filterBusinessPartners, setFilterBusinessPartners] = React.useState([]);
  const [filterOrderIds, setfilterOrderIds] = React.useState([]);
  const [filterEndUsers, setFilterEndUsers] = React.useState([]);
  const [filterCreationDateStart, setFilterCreationDateStart] = React.useState();
  const [filterCreationDateEnd, setFilterCreationDateEnd] = React.useState();
  const [filterModificationDateStart, setFilterModificationDateStart] = React.useState();
  const [filterModificationDateEnd, setFilterModificationDateEnd] = React.useState();

  const handleExecuteSearch = () => {
    let queryObject = {}
    if (queryParameters?.applicationIds) {
      queryObject.application_id = queryParameters?.applicationIds;
    };
    if (queryParameters?.businessPartners) {
      queryObject.business_partner = queryParameters?.businessPartners;
    };
    if (queryParameters?.soldTos) {
      queryObject.sold_to = queryParameters?.soldTos;
    };
    if (queryParameters?.order) {
      queryObject.order = queryParameters?.order;
    };
    if (queryParameters?.endUser) {
      queryObject.end_user = queryParameters?.endUser;
    };
    if (queryParameters?.supportPartner) {
      queryObject.support_partner = queryParameters?.supportPartner;
    };
    if (queryParameters?.creationDateStart && queryParameters?.creationDateEnd) {
      // TODO: Ask Louis about changing the Lambda to accomodate date ranges
      queryObject.creation_date_from = queryParameters?.creationDateStart;
      queryObject.creation_date_to = queryParameters?.creationDateEnd;
    };
    dispatch(getSystemApplications(queryObject));
  };

  const handleExecuteFilter = () => {
    let filterObject = {}
    if (filterApplicationIds?.length > 0) {
      filterObject.applicationIds = [filterApplicationIds];
    };
    if (filterBusinessPartners?.length > 0) {
      filterObject.businessPartners = [filterBusinessPartners];
    };
    if (filterSoldTos?.length > 0) {
      filterObject.soldTos = [filterSoldTos];
    };
    if (filterEndUsers?.length > 0) {
      filterObject.endUsers = [filterEndUsers];
    };
    if (filterOrderIds?.length > 0) {
      filterObject.orderIds = [filterOrderIds];
    };
    if (filterCreationDateStart) {
      filterObject.filterCreationDateStart = filterCreationDateStart;
    };
    if (filterCreationDateEnd) {
      filterObject.filterCreationDateEnd = filterCreationDateEnd;
    };
    dispatch(filterSystemApplications(filterObject));
  };


  useEffect(() => {
    handleExecuteFilter()
  }, [
    filterApplicationIds,
    filterSoldTos,
    filterEndUsers,
    filterBusinessPartners,
    filterOrderIds,
    filterCreationDateStart,
    filterCreationDateEnd,
    filterModificationDateStart,
    filterModificationDateEnd
  ]);

  function handleSelectSystemApplication(applicationId) {
    dispatch(getSystemApplicationDetails(applicationId));
    navigate('/systemapplications/details/' + applicationId);
  };

  const handleDeleteApplicationIds = (chipToDelete) => () => {
    dispatch(removeParameter({ systemApplications: { applicationIds: [chipToDelete] } }));
  };

  const handleClearApplicationIds = () => {
    dispatch(clearParameters({ systemApplications: 'applicationIds' }));
  };

  const handleDeleteBusinessPartner = (chipToDelete) => () => {
    dispatch(removeParameter({ systemApplications: { businessPartners: [chipToDelete] } }));
  };

  const handleClearBusinessPartners = () => {
    dispatch(clearParameters({ systemApplications: 'businessPartners' }));
  };

  const handleDeleteSoldTo = (chipToDelete) => () => {
    dispatch(removeParameter({ systemApplications: { soldTos: [chipToDelete] } }));
  };

  const handleClearSoldTo = () => {
    dispatch(clearParameters({ systemApplications: 'soldTos' }));
  };

  const handleDeleteOrder = (chipToDelete) => () => {
    dispatch(removeParameter({ systemApplications: { order: [chipToDelete] } }));
  };

  const handleClearOrder = () => {
    dispatch(clearParameters({ systemApplications: 'order' }));
  };

  const handleDeleteEndUser = (chipToDelete) => () => {
    dispatch(removeParameter({ systemApplications: { endUser: [chipToDelete] } }));
  };

  const handleClearEndUser = () => {
    dispatch(clearParameters({ systemApplications: 'endUser' }));
  };

  const handleDeleteSupportPartner = (chipToDelete) => () => {
    dispatch(removeParameter({ systemApplications: { supportPartner: [chipToDelete] } }));
  };

  const handleClearSupportPartner = () => {
    dispatch(clearParameters({ systemApplications: 'supportPartner' }));
  };

  // const handleExportToCSV = (materials) => {
  //   dispatch(exportMaterialsToCSV(materials))
  // };

  // const handleExportContractsToCSV = (materials) => {
  //   dispatch(exportMaterialContractsToCSV(materials))
  // };

  const systemApplications = useSelector(state => {
    if (state.filteredSystemApplications) {
      return state.filteredSystemApplications.map((systemApplication) => {
        return {
          application_id: systemApplication?.application_id,
          application_family: systemApplication?.application_family,
          business_partner: systemApplication?.business_partner?.company_id,
          comments: systemApplication?.comments,
          creation_date: systemApplication?.creation_date,
          creation_note: systemApplication?.creation_note,
          description: systemApplication?.description,
          end_user: systemApplication?.end_user?.company_id,
          inactive_reason: systemApplication?.inactive_reason,
          modification_date: systemApplication?.modification_date,
          order: systemApplication?.order?.order_id,
          part_number: systemApplication?.part_number,
          product: systemApplication?.product,
          sold_to: systemApplication?.sold_to?.applicant_id,
          status: systemApplication?.status,
          support_partner: systemApplication?.support_partner?.company_id,
          type: systemApplication?.type,
          // children: systemApplication?.children
        }
      })
    }
    else return []
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(document.getElementById("menuAnchor"));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterDescription = 'Add filters to the list of results';

  const [sortDirection, setSortDirection] = React.useState('');
  const [sortParameter, setSortParameter] = React.useState('');

  const handleSortSystemApplications = (sortParameter) => {
    setSortParameter(sortParameter);
    if (sortDirection === '') {
      setSortDirection('ascending');
      dispatch(sortSystemApplications(sortParameter, 'ascending'));
    } else if (sortDirection === 'ascending') {
      setSortDirection('descending');
      dispatch(sortSystemApplications(sortParameter, 'descending'));
    } else {
      setSortDirection('ascending');
      dispatch(sortSystemApplications(sortParameter, 'ascending'));
    };
  };

  const [expanded, setExpanded] = React.useState(null);

  const handleKeydown = (event) => {
    if (event.key == 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      setTimeout(() => {
        handleExecuteSearch();
      }, 0);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <React.Fragment>
      <div style={{ paddingBottom: '1%' }} />
      <Grid container className='headercontainer'>
      </Grid>
      <MultiValueInput
        setCurrentParam={setCurrentApplicationId}
        setQueryParams={setParameters}
        currentParam={currentApplicationId}
        queryParams={queryParameters?.applicationIds}
        handleDelete={handleDeleteApplicationIds}
        handleClear={handleClearApplicationIds}
        label="Application ID"
        family='systemApplications'
        type='applicationIds'
      />
      <MultiValueInput
        setCurrentParam={setCurrentBusinessPartner}
        setQueryParams={setParameters}
        currentParam={currentBusinessPartner}
        queryParams={queryParameters?.businessPartners}
        handleDelete={handleDeleteBusinessPartner}
        handleClear={handleClearBusinessPartners}
        label="Business Partner"
        family='systemApplications'
        type='businessPartners'
      />
      <MultiValueInput
        setCurrentParam={setCurrentSoldTo}
        setQueryParams={setParameters}
        currentParam={currentSoldTo}
        queryParams={queryParameters?.soldTos}
        handleDelete={handleDeleteSoldTo}
        handleClear={handleClearSoldTo}
        label="Sold To"
        family='systemApplications'
        type='soldTos'
      />
      <MultiValueInput
        setCurrentParam={setCurrentOrder}
        setQueryParams={setParameters}
        currentParam={currentOrder}
        queryParams={queryParameters?.order}
        handleDelete={handleDeleteOrder}
        handleClear={handleClearOrder}
        label="SAP Order"
        family='systemApplications'
        type='order'
      />
      <MultiValueInput
        setCurrentParam={setCurrentEndUser}
        setQueryParams={setParameters}
        currentParam={currentEndUser}
        queryParams={queryParameters?.endUser}
        handleDelete={handleDeleteEndUser}
        handleClear={handleClearEndUser}
        label="End Customer ID"
        family='systemApplications'
        type='endUser'
      />
      <MultiValueInput
        setCurrentParam={setCurrentSupportPartner}
        setQueryParams={setParameters}
        currentParam={currentSupportPartner}
        queryParams={queryParameters?.supportPartner}
        handleDelete={handleDeleteSupportPartner}
        handleClear={handleClearSupportPartner}
        label="Support Partner"
        family='systemApplications'
        type='supportPartner'
      />
      <Grid>
        <SearchDateInput
          title={'Creation Date'}
          setFilterDateStart={setParameters}
          setFilterDateEnd={setParameters}
          family='systemApplications'
          type='creationDateStart'
          type2='creationDateEnd'
        />
      </Grid>
      <Grid
        container
        style={{
          justifyContent: 'center'
        }}
      >
        <Grid item>
          <Button variant="contained" onClick={handleExecuteSearch} style={{ width: '15vw' }}>Search</Button>
        </Grid>
        <div style={{ paddingLeft: '0.2%' }} />
      </Grid>
      <Title>System Applications</Title>
      <Typography>
        {systemApplications?.length ?
          // systemApplications?.length === 100 ?
          // systemApplications?.length + ' results, use bulk export for more' :
          systemApplications?.length + ' results' :
          null
        }
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Application ID"
                    variant='standard'
                    onChange={(e) => { setFilterApplicationIds(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSystemApplications('application_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'application_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'application_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  Creation Date
                </Grid>
                <Grid
                  item
                  onClick={() => handleSortSystemApplications('creation_date')}
                >
                  {sortDirection === 'ascending' && sortParameter === 'creation_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'creation_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  Modification Date
                </Grid>
                <Grid
                  item
                  onClick={() => handleSortSystemApplications('modification_date')}
                >
                  {sortDirection === 'ascending' && sortParameter === 'modification_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'modification_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Sold To"
                    variant='standard'
                    onChange={(e) => { setFilterSoldTos(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSystemApplications('sold_to.applicant_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'sold_to.applicant_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'sold_to.applicant_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Business Partner"
                    variant='standard'
                    onChange={(e) => { setFilterBusinessPartners(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSystemApplications('business_partner.company_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'business_partner.company_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'business_partner.company_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Order ID"
                    variant='standard'
                    onChange={(e) => { setfilterOrderIds(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortSystemApplications('order.order_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'order.order_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'order.order_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {systemApplications.map((row, i) => (
            <React.Fragment
              key={row?.serial_number + i}
            >
              <TableRow
                hover
                onClick={() => { handleSelectSystemApplication(row?.application_id) }}
                style={{
                  cursor: 'pointer'
                }}>
                <TableCell>
                  <Tooltip title={row?.children ? 'Show/hide children' : ''} placement="top">
                    <span>
                      <IconButton
                        style={{
                          // display: row?.children ? 'block' : 'none',
                          opacity: row?.children ? 1 : 0
                        }}
                        disabled={row?.children ? false : true}
                        onClick={(e) => {
                          if (expanded === row?.serial_number) {
                            setExpanded()
                          } else {
                            setExpanded(row?.serial_number)
                          }
                          e.stopPropagation()
                        }}
                      >
                        {expanded === row?.serial_number && <KeyboardArrowDownIcon />}
                        {expanded !== row?.serial_number && <KeyboardArrowRightIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={row?.parent ? 'Show/hide parent' : ''} placement="top">
                    <span>
                      <IconButton
                        style={{
                          // display: row?.children ? 'block' : 'none',
                          opacity: row?.parent ? 1 : 0
                        }}
                        disabled={row?.parent ? false : true}
                        onClick={(e) => {
                          if (expanded === row?.serial_number) {
                            setExpanded()
                          } else {
                            setExpanded(row?.serial_number)
                          }
                          e.stopPropagation()
                        }}
                      >
                        {expanded === row?.serial_number && <KeyboardArrowDownIcon />}
                        {expanded !== row?.serial_number && <KeyboardArrowRightIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                  {row?.application_id}
                </TableCell>
                <TableCell>{row?.creation_date}</TableCell>
                <TableCell>{row?.modification_date}</TableCell>
                <TableCell>{row?.sold_to}</TableCell>
                <TableCell>{row?.business_partner}</TableCell>
                <TableCell>{row?.order}</TableCell>
              </TableRow>
              <Accordion
                expanded={expanded === row?.serial_number && row?.children}
              >
                <AccordionSummary style={{ display: 'none' }} />
                <AccordionDetails>
                  {/* <MaterialChildren material={row} /> */}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === row?.serial_number && row?.parent}
              >
                <AccordionSummary style={{ display: 'none' }} />
                <AccordionDetails>
                  {/* <MaterialParents material={row} /> */}
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      <Tooltip
        title={<Typography fontSize={17}>{filterDescription}</Typography>}
      >
        <Fab
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            backgroundColor: "#6b469e",
            color: "white",
            height: "5em",
            width: "5em"
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <div
            id="menuAnchor"
            style={{
              position: 'relative',
              bottom: 500,
              right: 250
            }}
          >
          </div>
          <FileDownloadIcon fontSize="large" style={{ height: "70%", width: "70%" }} />
        </Fab>
      </Tooltip>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem >
            <TextField
              label="Serial Number"
              onChange={(e) => { setFilterApplicationIds(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <Grid>
              <div>
                Creation Date
              </div>
              <DateInput
                setFilterDateStart={setFilterCreationDateStart}
                setFilterDateEnd={setFilterCreationDateEnd}
              />
            </Grid>
          </MenuItem>
          <MenuItem >
            <Grid>
              <div>
                Modification Date
              </div>
              <DateInput
                setFilterDateStart={setFilterModificationDateStart}
                setFilterDateEnd={setFilterModificationDateEnd}
              />
            </Grid>
          </MenuItem>
          <MenuItem >
            <TextField
              label="Sold To"
              onChange={(e) => { setFilterSoldTos(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <TextField
              label="Business Partner"
              onChange={(e) => { setFilterBusinessPartners(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment >
  );
}
