export const GET_SYSTEM_APPLICATIONS = 'GET_SYSTEM_APPLICATIONS';
export const GET_SYSTEM_APPLICATION_DETAILS = 'GET_SYSTEM_APPLICATION_DETAILS';
export const FILTER_SYSTEM_APPLICATIONS = 'FILTER_SYSTEM_APPLICATIONS';
export const SORT_SYSTEM_APPLICATIONS = 'SORT_SYSTEM_APPLICATIONS';



export function getSystemApplications(queryParameters) {
    console.log("Action GET_SYSTEM_APPLICATIONS", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_SYSTEM_APPLICATIONS, data: queryParameters };
};

export function getSystemApplicationDetails(systemApplicationId) {
    console.log("Action GET_SYSTEM_APPLICATION_DETAILS");
    return {
        type: GET_SYSTEM_APPLICATION_DETAILS, data: {
            application_id: systemApplicationId,
            show_entitlements: true
        }
    };
};

export function filterSystemApplications(filterParameters) {
    console.log("Action FILTER_SYSTEM_APPLICATIONS", filterParameters);
    return { type: FILTER_SYSTEM_APPLICATIONS, data: filterParameters };
};

export function sortSystemApplications(sortParameter, direction) {
    console.log("Action SORT_SYSTEM_APPLICATIONS");
    return { type: SORT_SYSTEM_APPLICATIONS, data: { sortParameter, direction } };
};
