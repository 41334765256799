export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACT_DETAILS = 'GET_CONTRACT_DETAILS';
export const FILTER_CONTRACTS = 'FILTER_CONTRACTS';
export const GET_CONTRACTS_BULK = 'GET_CONTRACTS_BULK';
export const EXPORT_CONTRACT_MATERIALS_TO_CSV = 'EXPORT_CONTRACT_MATERIALS_TO_CSV';
export const SORT_CONTRACTS = 'SORT_CONTRACTS';
export const EXPORT_CONTRACTS_TO_CSV = 'EXPORT_CONTRACTS_TO_CSV';

export function filterContracts(filterParameters) {
    console.log("Action FILTER_CONTRACTS", filterParameters);
    return { type: FILTER_CONTRACTS, data: filterParameters };
};

export function getContracts(queryParameters) {
    console.log("Action GET_CONTRACTS", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_CONTRACTS, data: queryParameters };
};

export function getContractDetails(contractReference, type) {
    console.log("Action GET_CONTRACT_DETAILS");
    return {
        type: GET_CONTRACT_DETAILS, data: {
            contract_reference: contractReference,
            type: type,
            show_parents: true,
            show_entitlements: true
        }
    };
};

export function getContractsBulk(queryParameters) {
    console.log("Action GET_CONTRACTS_BULK", queryParameters);
    return { type: GET_CONTRACTS_BULK, data: queryParameters };
};

export function sortContracts(sortParameter, direction) {
    console.log("Action SORT_CONTRACTS");
    return { type: SORT_CONTRACTS, data: { sortParameter, direction } };
};

export function exportContractsToCSV(contracts) {
    console.log("Action EXPORT_CONTRACTS_TO_CSV");
    return { type: EXPORT_CONTRACTS_TO_CSV, contracts };
};

export function exportContractMaterialsToCSV(contract) {
    console.log("Action EXPORT_CONTRACT_MATERIALS_TO_CSV");
    return { type: EXPORT_CONTRACT_MATERIALS_TO_CSV, data: { contract } };
};