/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const get_contract = /* GraphQL */ `
  query Get_contract($contract_reference: String!, $contract_type: String!) {
    get_contract(
      contract_reference: $contract_reference
      contract_type: $contract_type
    ) {
      contract_reference
      contract_type
      status
      entitlements {
        start_date
        end_date
        status
      }
      start_date
      end_date
      creation_date
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      quantity
      description
      services {
        type
      }
      modification_date
    }
  }
`;
export const get_contracts = /* GraphQL */ `
  query Get_contracts(
    $order_id: [String!]
    $contract_reference: [String!]
    $sold_to: [String!]
    $contract_type: [String!]
  ) {
    get_contracts(
      order_id: $order_id
      contract_reference: $contract_reference
      sold_to: $sold_to
      contract_type: $contract_type
    ) {
      contract_reference
      contract_type
      status
      entitlements {
        start_date
        end_date
        status
      }
      start_date
      end_date
      creation_date
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      quantity
      description
      services {
        type
      }
      modification_date
    }
  }
`;
export const get_material = /* GraphQL */ `
  query Get_material($serial_number: String!) {
    get_material(serial_number: $serial_number) {
      serial_number
      asset_id
      part_number
      parent {
        serial_number
        asset_id
        part_number
        sn_type
        description
        status
        creation_date
        modification_date
      }
      children {
        serial_number
        asset_id
        part_number
        sn_type
        description
        status
        creation_date
        modification_date
      }
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      sn_type
      description
      status
      entitlements {
        start_date
        end_date
        status
      }
      creation_date
      modification_date
    }
  }
`;
export const get_materials = /* GraphQL */ `
  query Get_materials(
    $order_id: [String!]
    $serial_number: [String!]
    $sold_to: [String!]
    $business_partner: [String!]
    $support_partner: [String!]
    $end_user: [String!]
  ) {
    get_materials(
      order_id: $order_id
      serial_number: $serial_number
      sold_to: $sold_to
      business_partner: $business_partner
      support_partner: $support_partner
      end_user: $end_user
    ) {
      serial_number
      asset_id
      part_number
      parent {
        serial_number
        asset_id
        part_number
        sn_type
        description
        status
        creation_date
        modification_date
      }
      children {
        serial_number
        asset_id
        part_number
        sn_type
        description
        status
        creation_date
        modification_date
      }
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      sn_type
      description
      status
      entitlements {
        start_date
        end_date
        status
      }
      creation_date
      modification_date
    }
  }
`;
export const get_material_entitlement = /* GraphQL */ `
  query Get_material_entitlement(
    $serial_number: String!
    $token: Int
    $limit: Int
  ) {
    get_material_entitlement(
      serial_number: $serial_number
      token: $token
      limit: $limit
    ) {
      entitlements {
        start_date
        end_date
        status
      }
      nextToken
    }
  }
`;
export const get_contract_entitlement = /* GraphQL */ `
  query Get_contract_entitlement(
    $contract_reference: String!
    $contract_type: String!
    $asset_types: [AssetTypes]
    $token: Int
    $limit: Int
  ) {
    get_contract_entitlement(
      contract_reference: $contract_reference
      contract_type: $contract_type
      asset_types: $asset_types
      token: $token
      limit: $limit
    ) {
      entitlements {
        start_date
        end_date
        status
      }
      nextToken
    }
  }
`;
export const get_application = /* GraphQL */ `
  query Get_application($application_id: String!) {
    get_application(application_id: $application_id) {
      application_id
      asset_id
      part_number
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      description
      status
      entitlements {
        start_date
        end_date
        status
      }
      creation_date
      product
      application_family
      modification_date
    }
  }
`;
export const get_applications = /* GraphQL */ `
  query Get_applications(
    $order_id: [String!]
    $application_id: [String!]
    $sold_to: [String!]
  ) {
    get_applications(
      order_id: $order_id
      application_id: $application_id
      sold_to: $sold_to
    ) {
      application_id
      asset_id
      part_number
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      description
      status
      entitlements {
        start_date
        end_date
        status
      }
      creation_date
      product
      application_family
      modification_date
    }
  }
`;
export const get_system = /* GraphQL */ `
  query Get_system($system_id: String!) {
    get_system(system_id: $system_id) {
      system_id
      asset_id
      part_number
      parent {
        system_id
        asset_id
        part_number
        description
        status
        creation_date
        cpu_id1
        cpu_id2
        modification_date
      }
      children {
        system_id
        asset_id
        part_number
        description
        status
        creation_date
        cpu_id1
        cpu_id2
        modification_date
      }
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      description
      status
      entitlements {
        start_date
        end_date
        status
      }
      creation_date
      cpu_id1
      cpu_id2
      modification_date
    }
  }
`;
export const get_systems = /* GraphQL */ `
  query Get_systems(
    $order_id: [String!]
    $system_id: [String!]
    $sold_to: [String!]
  ) {
    get_systems(order_id: $order_id, system_id: $system_id, sold_to: $sold_to) {
      system_id
      asset_id
      part_number
      parent {
        system_id
        asset_id
        part_number
        description
        status
        creation_date
        cpu_id1
        cpu_id2
        modification_date
      }
      children {
        system_id
        asset_id
        part_number
        description
        status
        creation_date
        cpu_id1
        cpu_id2
        modification_date
      }
      sold_to {
        applicant_id
      }
      order {
        order_id
        order_line
        purchase_order
      }
      end_user {
        company_id
        source
      }
      business_partner {
        company_id
        source
      }
      support_partner {
        company_id
        source
      }
      description
      status
      entitlements {
        start_date
        end_date
        status
      }
      creation_date
      cpu_id1
      cpu_id2
      modification_date
    }
  }
`;

export const get_materials_entitlements = /* GraphQL */ `
  query Get_materials(
    $order_id: [String!]
    $serial_number: [String!]
    $sold_to: [String!]
    $business_partner: [String!]
    $support_partner: [String!]
    $end_user: [String!]
  ) {
    get_materials(
      order_id: $order_id
      serial_number: $serial_number
      sold_to: $sold_to
      business_partner: $business_partner
      support_partner: $support_partner
      end_user: $end_user
    ) {
      entitlements {
        start_date
        end_date
        contract {
          contract_reference
          contract_type
        }
      }
      serial_number
      part_number
      end_user {
        company_id
        source
      }
    }
  }
`;

export const get_contracts_entitlements = /* GraphQL */ `
  query Get_contracts(
    $contract_reference: [String!]
    $contract_type: [String!]
  ) {
    get_contracts(
      contract_reference: $contract_reference
      contract_type: $contract_type
    ) {
      entitlements {
        start_date
        end_date
        status
        asset {
          ... on Material {
            serial_number
          }
          ... on Material_Placeholder {
            serial_number
            bundle_reference
          }
          ... on System {
            system_id
          }
          ... on Application {
            application_id
          }
          ... on License {
            license_id
          }
          part_number
          order {
            order_id
            order_line
          }
          creation_date
          modification_date
          __typename
        }
      }
    }
  }
`;
