export const GET_LICENSES = 'GET_LICENSES';
export const GET_LICENSE_DETAILS = 'GET_LICENSE_DETAILS';
export const FILTER_LICENSES = 'FILTER_LICENSES';
export const SORT_LICENSES = 'SORT_LICENSES';

export function filterLicenses(filterParameters) {
    console.log("Action FILTER_LICENSES", filterParameters);
    return { type: FILTER_LICENSES, data: filterParameters };
};

export function getLicenses(queryParameters) {
    console.log("Action GET_LICENSES", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_LICENSES, data: queryParameters };
};

export function getLicenseDetails(licenseId) {
    console.log("Action GET_LICENSE_DETAILS");
    return {
        type: GET_LICENSE_DETAILS, data: {
            license_id: licenseId,
            show_parents: true,
            show_entitlements: true
        }
    };
}

export function sortLicenses(sortParameter, direction) {
    console.log("Action SORT_LICENSES");
    return { type: SORT_LICENSES, data: { sortParameter, direction } };
};