// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

 

const awsmobile =  {
    "aws_appsync_graphqlEndpoint": `https://app-sync.${process.env.REACT_APP_ENVIRONMENT}.se-engine.datalake.systems/graphql`,
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "null",
};

 

export default awsmobile;
// aws appsync get-introspection-schema --api-id 7yduroodczg7hg266ud6axe6q4 --format SDL schema.graphql --region eu-west-1
// npx amplify add codegen --apiId 7yduroodczg7hg266ud6axe6q4