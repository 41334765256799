import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import { useDispatch } from 'react-redux';

import Customer from './tables/customer';
import System from './tables/system';
import Contracts from './tables/contracts';
import { getSystemDetails } from '../redux/system/actions';
import { getContractDetails } from '../redux/contract/actions';

export default function SystemDetails() {

    const dispatch = useDispatch();
    const location = useLocation();

    let systemId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const system = useSelector((state) => {
        let _system = state?.systems?.find(s => s.system_id === systemId);
        if (_system) {
            return JSON.parse(JSON.stringify(_system));
        } else return {}
    });
    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!system && !loading && !errors) {
            dispatch(getSystemDetails(systemId));
        };
    }, []);

    return (<>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <System />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Customer />
        </div>
        <div
            style={{ marginBottom: '1%' }}
        />
        <Contracts getContractDetails={getContractDetails} />

    </>)
}
