import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "eu-west-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN_PREFIX + '.auth.' + process.env.REACT_APP_REGION + '.amazoncognito.com',
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      responseType: "code"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
