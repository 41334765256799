import { Grid } from '@mui/material';
import * as React from 'react';

export default function DateInput(props) {

    return (
        <Grid container direction={"column"}>
            <Grid item>
                <input type="date" id="start" name="start" onChange={(e) => { props.setFilterDateStart(e.target.value) }} />
            </Grid>
            <Grid item>
                <input type="date" id="end" name="end" onChange={(e) => { props.setFilterDateEnd(e.target.value) }} />
            </Grid>
        </Grid>
    );
}
