import {
    GET_CONTRACTS,
    GET_CONTRACT_DETAILS,
    SORT_CONTRACTS,
    GET_CONTRACTS_BULK,
    FILTER_CONTRACTS,
    EXPORT_CONTRACT_MATERIALS_TO_CSV,
    EXPORT_CONTRACTS_TO_CSV,
} from '../actions';

import { Auth } from 'aws-amplify';

import { API, graphqlOperation } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import * as queries from '../../../graphql/queries.js';
import { restfulResources, getFromServer, getFromGraphQlApi } from '../../global/middleware';

let originalFetch = require('isomorphic-fetch');
let fetch = require('fetch-retry')(originalFetch);

API.configure(config)

let questObject;
let getResult;
let contractsToExport;
let headers;

export function contractMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            //TODO: Error handling
            switch (action.type) {
                //todo do EXPORT_CONTRACT_MATERIALS_TO_CSV, handle system and others
                case FILTER_CONTRACTS:
                    break;
                case GET_CONTRACTS:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'contracts';

                    getResult = await getFromServer(questObject);
                    if (getResult.responseCode < 202 && getResult !== 0) {
                        action.results = getResult.results.map((result) => ({
                            ...result,
                            orderId: result?.order?.order_id,
                            contract_identifier: result?.contract_reference + result?.type
                        }));
                    } else {
                        action.errors = { responseCode: getResult.responseCode };
                        console.log('action.errors', action.errors, 'getResult', getResult);
                    };
                    break;
                case GET_CONTRACT_DETAILS:
                    getResult = await getFromGraphQlApi(queries.get_contracts_entitlements, {
                        contract_reference: action.data.contract_reference,
                        contract_type: action.data.type,
                    });
                    if (getResult) {
                        const assets = getResult?.data?.get_contracts?.flatMap((contract) => {
                            return contract?.entitlements?.map((entitlement) => ({
                                ...entitlement.asset,
                                contract_reference: action.data.contract_reference,
                                status: entitlement.status,
                                entitlement_start_date: entitlement.start_date,
                                entitlement_end_date: entitlement.end_date,
                            }));
                        });

                        console.log('assets', assets);
                        action.results = assets;
                    }
                    break;
                case SORT_CONTRACTS:
                    break;
                case EXPORT_CONTRACTS_TO_CSV:
                    headers = [
                        "contract_reference",
                        "status",
                        "sold_to",
                        "type", 
                        "entitlements"
                    ];
                
                    contractsToExport = headers.join(",") + "\n";
                
                    for (let contract of action?.contracts) {
                        const getResult = await getFromGraphQlApi(queries.get_contracts_entitlements, {
                            contract_reference: contract?.contract_reference,
                            contract_type: contract?.type,
                        });
                        
                        let entitlements = getResult.data.get_contracts.flatMap(contract => contract.entitlements).map(entitlement => entitlement.asset.serial_number).join("; ");
                
                        let newContractArray = [
                            contract?.contract_reference,
                            contract?.status,
                            contract?.sold_to,
                            contract?.type, 
                            `"${entitlements}"`
                        ];
                
                        contractsToExport = contractsToExport + newContractArray.join(",") + "\n";
                    };
                    action.CSVContracts = contractsToExport;
                    break;              
                case GET_CONTRACTS_BULK:
                    questObject = {};
                    questObject.params = Object.assign({}, action.data);
                    questObject.resource = 'bulkContracts';

                    let bulkResult = await getFromServer(questObject);
                    let url = bulkResult?.url;

                    let bulkResponse = await fetch(url, {
                        retries: 60,
                        retryDelay: 3000,
                        retryOn: function (attempt, error, response) {
                            // retry on any network error, or 4xx or 5xx status codes
                            if (error !== null || response.status >= 400) {
                                console.log(`retrying, attempt number ${attempt + 1}`);
                                return true;
                            }
                        }
                    }).catch(err => console.log('err', err))
                        .then(async response => {
                            const text = await response.text();
                            return {
                                response,
                                text
                            };
                        });
                    let jsonParsedResponse = JSON.parse(bulkResponse.text);
                    for (let entry of jsonParsedResponse) {
                        entry.order_id = entry?.order?.order_id;
                        entry.order_line = entry?.order?.order_line;
                        entry.purchase_order = entry?.order?.purchase_order;
                        delete entry.order;
                        entry.sold_to = entry?.sold_to?.applicant_id;
                        delete entry.services
                    };
                    console.log('jsonParsedResponse', jsonParsedResponse)
                    action.bulkResponse = jsonParsedResponse;
                    break;
            };
            return next(action);

        }
    }
}