export const GET_MATERIALS = 'GET_MATERIALS';
export const GET_MATERIAL_DETAILS = 'GET_MATERIAL_DETAILS';
export const FILTER_MATERIALS = 'FILTER_MATERIALS';
export const GET_MATERIALS_BULK = 'GET_MATERIALS_BULK';
export const SORT_MATERIALS = 'SORT_MATERIALS';
export const EXPORT_MATERIALS_TO_CSV = 'EXPORT_MATERIALS_TO_CSV';
export const EXPORT_MATERIALS_CONTRACTS_TO_CSV = 'EXPORT_MATERIALS_CONTRACTS_TO_CSV';
export const GET_CHILD = 'GET_CHILD';
export const GET_CANDIDATE_MATERIALS = 'GET_CANDIDATE_MATERIALS';

export function getMaterials(queryParameters) {
    console.log("Action GET_MATERIALS", queryParameters);
    queryParameters.limit = 100;
    return { type: GET_MATERIALS, data: queryParameters };
};

export function getMaterialDetails(serialNumber, orderId, orderLine, placeholder) {
    console.log("Action GET_MATERIAL_DETAILS", serialNumber, orderId, orderLine, placeholder);
    return {
        type: GET_MATERIAL_DETAILS, data: {
            serial_number: serialNumber,
            order_id: orderId,
            order_line: orderLine,
            show_parents: true,
            show_entitlements: true,
            placeholder: placeholder
        }
    };
};

export function filterMaterials(filterParameters) {
    console.log("Action FILTER_MATERIALS", filterParameters);
    return { type: FILTER_MATERIALS, data: filterParameters };
};

export function exportMaterialsToCSV(materials) {
    console.log("Action EXPORT_TO_CSV");
    return { type: EXPORT_MATERIALS_TO_CSV, materials };
};

export function exportMaterialContractsToCSV(materials, type) {
    console.log("Action EXPORT_MATERIALS_CONTRACTS_TO_CSV");
    return { type: EXPORT_MATERIALS_CONTRACTS_TO_CSV, data: { materials, type } };
};

export function sortMaterials(sortParameter, direction) {
    console.log("Action SORT_MATERIALS");
    return { type: SORT_MATERIALS, data: { sortParameter, direction } };
};

export function getMaterialsBulk(queryParameters) {
    console.log("Action GET_MATERIALS_BULK", queryParameters);
    return { type: GET_MATERIALS_BULK, data: queryParameters };
};

export function getChild(queryParameters) {
    console.log("Action GET_CHILD", queryParameters);
    return { type: GET_CHILD, data: queryParameters };
};

export function getCandidateMaterials(queryParameters) {
    console.log("Action GET_CANDIDATE_MATERIALS");
    return { type: GET_CANDIDATE_MATERIALS, data: queryParameters };
};
