import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { useSelector } from 'react-redux';
import {
    clearParameters,
    removeParameter,
    setParameters,
} from '../redux/global/actions';
import {
    exportContractsToCSV,
    filterContracts,
    getContractDetails,
    getContracts,
    getContractsBulk,
    sortContracts,
} from '../redux/contract/actions';
import {
    useNavigate
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import MultiValueInput from '../inputs/multiValueInput';
import DateInput from '../inputs/dateInput';
import { Fab, Grid, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect } from 'react';
import './Contracts.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchDateInput from '../inputs/searchDateInput';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export default function Contracts() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const queryParameters = useSelector(state => state?.parameters?.contracts);

    const [currentContractReference, setCurrentContractReference] = React.useState();
    const [currentContractSoldTo, setCurrentContractSoldTo] = React.useState();
    const [currentContractOrderId, setCurrentContractOrderId] = React.useState();
    const [currentContractType, setCurrentContractType] = React.useState();

    const [filterContractReference, setFilterContractReference] = React.useState([]);
    const [filterType, setFilterType] = React.useState([]);
    const [filterSoldTos, setFilterSoldTos] = React.useState([]);
    const [filterStatus, setFilterStatus] = React.useState([]);
    const [filterOrderIds, setFilterOrderIds] = React.useState([]);
    const [filterCreationDateStart, setFilterCreationDateStart] = React.useState();
    const [filterCreationDateEnd, setFilterCreationDateEnd] = React.useState();
    const [filterModificationDateStart, setFilterModificationDateStart] = React.useState();
    const [filterModificationDateEnd, setFilterModificationDateEnd] = React.useState();

    const handleExecuteSearch = () => {
        let queryObject = {}
        if (queryParameters.contractReferences) {
            queryObject.contract_reference = queryParameters.contractReferences;
        };
        if (queryParameters.soldTos) {
            queryObject.sold_to = queryParameters.soldTos;
        };
        if (queryParameters.orderIds) {
            queryObject.order = queryParameters.orderIds;
        };
        if (queryParameters.types) {
            queryObject.type = queryParameters.types;
        };
        if (queryParameters.creationDateStart && queryParameters.creationDateEnd) {
            queryObject.creation_date_from = queryParameters.creationDateStart;
            queryObject.creation_date_to = queryParameters.creationDateEnd;
        };
        dispatch(getContracts(queryObject));
    };

    const handleExecuteFilter = () => {
        let filterObject = {};
        if (filterContractReference?.length > 0) {
            filterObject.contractReference = [filterContractReference];
        };
        if (filterType?.length > 0) {
            filterObject.type = [filterType];
        };
        if (filterSoldTos?.soldTos > 0) {
            filterObject.soldTos = [filterSoldTos];
        };
        if (filterStatus?.status > 0) {
            filterObject.status = [filterStatus];
        };
        if (filterStatus?.orderIds > 0) {
            filterObject.orderIds = [filterOrderIds];
        };
        if (filterCreationDateStart) {
            filterObject.filterCreationDateStart = filterCreationDateStart;
        };
        if (filterCreationDateEnd) {
            filterObject.filterCreationDateEnd = filterCreationDateEnd;
        };
        if (filterModificationDateStart) {
            filterObject.filterModificationDateStart = filterModificationDateStart;
        };
        if (filterModificationDateEnd) {
            filterObject.filterModificationDateEnd = filterModificationDateEnd;
        };
        dispatch(filterContracts(filterObject));
    };

    const handleExecuteBulkSearch = () => {
        let queryObject = {}
        if (queryParameters.contractReferences) {
            queryObject.contract_reference = queryParameters.contractReferences;
        };
        if (queryParameters.soldTos) {
            queryObject.sold_to = queryParameters.soldTos;
        };
        if (queryParameters.orderIds) {
            queryObject.order = queryParameters.orderIds;
        };
        if (queryParameters.types) {
            queryObject.type = queryParameters.types;
        };
        if (queryParameters.creationDateStart && queryParameters.creationDateEnd) {
            queryObject.creation_date_from = queryParameters.creationDateStart;
            queryObject.creation_date_to = queryParameters.creationDateEnd;
        };
        dispatch(getContractsBulk(queryObject));
    };

    useEffect(() => {
        handleExecuteFilter()
    }, [
        filterContractReference,
        filterType,
        filterSoldTos,
        filterStatus,
        filterOrderIds,
        filterCreationDateStart,
        filterCreationDateEnd,
        filterModificationDateStart,
        filterModificationDateEnd
    ]);

    function handleSelectContract(serialNumber, type) {
        dispatch(getContractDetails(serialNumber, type));
        navigate('/contracts/details/' + serialNumber + '/' + type);
    };

    const handleDeleteContractReference = (chipToDelete) => () => {
        dispatch(removeParameter({ contracts: { contractReferences: [chipToDelete] } }));
    };
    const handleClearContractReferences = () => {
        dispatch(clearParameters({ contracts: 'contractReferences' }));
    };
    const handleDeleteContractSoldTo = (chipToDelete) => () => {
        dispatch(removeParameter({ contracts: { soldTos: [chipToDelete] } }));
    };
    const handleClearContractSoldTos = () => {
        dispatch(clearParameters({ contracts: 'soldTos' }))
    };
    const handleDeleteContractOrderId = (chipToDelete) => () => {
        dispatch(removeParameter({ contracts: { orderIds: [chipToDelete] } }));
    };
    const handleClearContractOrderIds = () => {
        dispatch(clearParameters({ contracts: 'orderIds' }))
    };
    const handleDeleteContractType = (chipToDelete) => () => {
        dispatch(removeParameter({ contracts: { types: [chipToDelete] } }));
    };
    const handleClearContractTypes = () => {
        dispatch(clearParameters({ contracts: 'types' }))
    };

    const handleExportToCSV = (contracts) => {
        dispatch(exportContractsToCSV(contracts))
    };

    const contracts = useSelector(state => {
        if (state.filteredContracts) {
            return state.filteredContracts.map((contract) => {
                return {
                    // Because contract_reference isn't always unique, a new identifier is composed from the reference and type
                    contract_identifier: contract?.contract_reference + contract.type,
                    contract_reference: contract?.contract_reference,
                    modification_date: contract?.modification_date,
                    creation_date: contract?.creation_date,
                    description: contract?.description,
                    duration: contract?.duration,
                    start_date: contract?.start_date,
                    end_date: contract?.end_date,
                    part_number: contract?.part_number,
                    quantity: contract?.quantity,
                    sold_to: contract?.sold_to?.applicant_id,
                    status: contract?.status,
                    type: contract?.type,
                    orderId: contract?.orderId
                }
            })
        }
        else return []
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(document.getElementById("menuAnchor"));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterDescription = 'Add filters to the list of results, and export the filtered list to CSV (currently not implemented for contracts)';


    const [sortDirection, setSortDirection] = React.useState('');
    const [sortParameter, setSortParameter] = React.useState('');

    const handleSortContracts = (sortParameter) => {
        setSortParameter(sortParameter);
        if (sortDirection === '') {
            setSortDirection('ascending');
            dispatch(sortContracts(sortParameter, 'ascending'));
        } else if (sortDirection === 'ascending') {
            setSortDirection('descending');
            dispatch(sortContracts(sortParameter, 'descending'));
        } else {
            setSortDirection('ascending');
            dispatch(sortContracts(sortParameter, 'ascending'));
        };
    };

    const [openBulkConfirmation, setOpenBulkConfirmation] = React.useState(false);

    const handleKeydown = (event) => {
        if (event.key == 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            setTimeout(() => {
                handleExecuteSearch();
            }, 0);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    return (
        <React.Fragment>
            <Modal
                open={openBulkConfirmation}
                style={{
                    textAlign: "center",
                    paddingTop: "45vh"
                }}>
                <>
                    <Typography
                        style={{ color: 'white' }}
                    >
                        This query could take a few minutes, are you sure you want to continue?
                    </Typography>
                    <div style={{ paddingBottom: '1%' }} />
                    <Grid container className='headercontainer'>
                        <Grid item>
                            <Button
                                style={{
                                    color: 'white',
                                    background: '#6b469e'
                                }}
                                onClick={() => { handleExecuteBulkSearch(); setOpenBulkConfirmation(false) }}
                            >Yes</Button>
                        </Grid>
                        <Grid item style={{ width: '7%' }} />
                        <Grid item>
                            <Button
                                style={{
                                    color: 'white',
                                    background: '#6b469e'
                                }}
                                onClick={() => setOpenBulkConfirmation(false)}
                            >No</Button>
                        </Grid>
                    </Grid>
                </>
            </Modal>
            <MultiValueInput
                setCurrentParam={setCurrentContractReference}
                setQueryParams={setParameters}
                currentParam={currentContractReference}
                queryParams={queryParameters?.contractReferences}
                handleDelete={handleDeleteContractReference}
                handleClear={handleClearContractReferences}
                label="Contract Reference"
                family='contracts'
                type='contractReferences'
            />
            <MultiValueInput
                setCurrentParam={setCurrentContractSoldTo}
                setQueryParams={setParameters}
                currentParam={currentContractSoldTo}
                queryParams={queryParameters?.soldTos}
                handleDelete={handleDeleteContractSoldTo}
                handleClear={handleClearContractSoldTos}
                label="Sold To"
                family='contracts'
                type='soldTos'
            />
            <MultiValueInput
                setCurrentParam={setCurrentContractOrderId}
                setQueryParams={setParameters}
                currentParam={currentContractOrderId}
                queryParams={queryParameters?.orderIds}
                handleDelete={handleDeleteContractOrderId}
                handleClear={handleClearContractOrderIds}
                label="Order ID"
                family='contracts'
                type='orderIds'
            />
            <MultiValueInput
                setCurrentParam={setCurrentContractType}
                setQueryParams={setParameters}
                currentParam={currentContractType}
                queryParams={queryParameters?.types}
                handleDelete={handleDeleteContractType}
                handleClear={handleClearContractTypes}
                label="Type"
                family='contracts'
                type='types'
            />
            <Grid>
                <SearchDateInput
                    title={'Creation Date'}
                    setFilterDateStart={setParameters}
                    setFilterDateEnd={setParameters}
                    family='contracts'
                    type='creationDateStart'
                    type2='creationDateEnd'
                />
            </Grid>
            <Grid
                container
                style={{
                    justifyContent: 'center'
                }}
            >
                <Grid item>
                    <Button variant="contained" onClick={handleExecuteSearch} style={{ width: '15vw' }}>Search</Button>
                </Grid>
                <div style={{ paddingLeft: '0.2%' }} />
                <Grid item>
                    <Button variant="contained" onClick={() => setOpenBulkConfirmation(true)} style={{ width: '15vw' }}>Bulk Export</Button>
                </Grid>
            </Grid>
            <Title>Contracts</Title>
            <Typography>
                {contracts?.length ?
                    contracts?.length === 100 ?
                        contracts?.length + ' results, use bulk export for more' :
                        contracts?.length + ' results' :
                    null
                }
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Contract Reference"
                                        variant='standard'
                                        onChange={(e) => { setFilterContractReference(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortContracts('contract_reference')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'contract_reference' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'contract_reference' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    Creation Date
                                </Grid>
                                <Grid
                                    item
                                    onClick={() => handleSortContracts('creation_date')}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'creation_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'creation_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    Modification Date
                                </Grid>
                                <Grid
                                    item
                                    onClick={() => handleSortContracts('modification_date')}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'modification_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'modification_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Type"
                                        variant='standard'
                                        onChange={(e) => { setFilterType(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortContracts('type')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'type' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'type' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Sold To"
                                        variant='standard'
                                        onChange={(e) => { setFilterType(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortContracts('sold_to.applicant_id')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'sold_to.applicant_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'sold_to.applicant_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Status"
                                        variant='standard'
                                        onChange={(e) => { setFilterStatus(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortContracts('status')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'status' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'status' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid container className='headercontainer'>
                                <Grid item>
                                    <TextField
                                        label="Order ID"
                                        variant='standard'
                                        onChange={(e) => { setFilterOrderIds(e.target.value) }}
                                        onKeyDown={(e) => { e.stopPropagation(); }}
                                    />
                                </Grid>
                                <Grid item
                                    onClick={() => handleSortContracts('orderId')}
                                    style={{ alignSelf: 'center' }}
                                >
                                    {sortDirection === 'ascending' && sortParameter === 'orderId' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                        sortDirection === 'descending' && sortParameter === 'orderId' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                            <UnfoldMoreIcon className='sortbutton' />
                                    }
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contracts.map((row) => (
                        <TableRow
                            hover
                            key={row?.contract_reference}
                            onClick={() => handleSelectContract(row?.contract_reference, row?.type)}
                            style={{
                                cursor: 'pointer'
                            }}>
                            <TableCell>{row?.contract_reference}</TableCell>
                            <TableCell>{row?.creation_date}</TableCell>
                            <TableCell>{row?.modification_date}</TableCell>
                            <TableCell>{row?.type}</TableCell>
                            <TableCell>{row?.sold_to}</TableCell>
                            <TableCell>{row?.status}</TableCell>
                            <TableCell>{row?.orderId}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Tooltip
                title={<Typography fontSize={17}>{filterDescription}</Typography>}
            >
                <Fab
                    style={{
                        position: 'absolute',
                        right: 10,
                        bottom: 10,
                        backgroundColor: "#6b469e",
                        color: "white",
                        height: "5em",
                        width: "5em"
                    }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <div
                        id="menuAnchor"
                        style={{
                            position: 'relative',
                            bottom: 500,
                            right: 250
                        }}
                    >
                    </div>
                    <FileDownloadIcon fontSize="large" style={{ height: "70%", width: "70%" }} />
                </Fab>
            </Tooltip>
            <div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem >
                        <TextField
                            label="Contract Reference"
                            onChange={(e) => { setFilterContractReference(e.target.value); }}
                            onKeyDown={(e) => { e.stopPropagation(); }}
                        />
                    </MenuItem>
                    <MenuItem >
                        <Grid>
                            <div>
                                Creation Date
                            </div>
                            <DateInput
                                setFilterDateStart={setFilterCreationDateStart}
                                setFilterDateEnd={setFilterCreationDateEnd}
                            />
                        </Grid>
                    </MenuItem>
                    <MenuItem >
                        <Grid>
                            <div>
                                Modification Date
                            </div>
                            <DateInput
                                setFilterDateStart={setFilterModificationDateStart}
                                setFilterDateEnd={setFilterModificationDateEnd}
                            />
                        </Grid>
                    </MenuItem>
                    <MenuItem >
                        <TextField
                            label="Type"
                            onChange={(e) => { setFilterType(e.target.value) }}
                            onKeyDown={(e) => { e.stopPropagation(); }}
                        />
                    </MenuItem>
                    <MenuItem >
                        <TextField
                            label="Sold To"
                            onChange={(e) => { setFilterSoldTos(e.target.value) }}
                            onKeyDown={(e) => { e.stopPropagation(); }}
                        />
                    </MenuItem>
                    <MenuItem >
                        <TextField
                            label="Status"
                            onChange={(e) => { setFilterStatus(e.target.value) }}
                            onKeyDown={(e) => { e.stopPropagation(); }}
                        />
                    </MenuItem>
                    <MenuItem >
                        <Button variant="contained" onClick={() => {
                            handleExportToCSV(contracts)
                        }}>Export contracts</Button>
                    </MenuItem>
                </Menu>
            </div>
        </React.Fragment>
    );
}
