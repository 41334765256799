import { Grid, Modal, Typography, Button } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import './alertModal.css'

export default function AlertModal() {

    const errors = useSelector(state => state.errors);
    const [openModal, setOpenModal] = React.useState(false);

    React.useEffect(() => {
        if (errors === 'timeout') {
            setOpenModal(true);
        }
    }, [errors])

    return (
        <Modal
            open={openModal}
            style={{
                textAlign: "center",
                paddingTop: "45vh"
            }}>
            <>
                <Typography
                    style={{ color: 'white' }}
                >
                    This query has timed out, please check your internet connection. If the issue persists, the query may be
                    better suited to a bulk search.
                </Typography>
                <div style={{ paddingBottom: '1%' }} />
                <Grid container className='headercontainer'>
                    <Grid item>
                        <Button
                            style={{
                                color: 'white',
                                background: '#6b469e'
                            }}
                            onClick={() => setOpenModal(false)}
                        >Ok</Button>
                    </Grid>
                </Grid>
            </>
        </Modal>
    );
}
